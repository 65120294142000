import { Typography } from "../../../../../components/common/Styled/Typography";

export const AgreementAttachment = () => (
  <Typography
    fontFamily="Montserrat"
    margin="20px 0 50px"
    size="16px"
    align="justify"
  >
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="left">
      Приложение № 1 к договору. Правила пользования помещением
    </Typography>
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="left">
      Правила бронирования
    </Typography>
    1. Кабинеты могут быть арендованы по часам или по дням. Минимальное время
    бронирования составляет 30 минут или 1 час, в зависимости от типа выбранного
    помещения. Арендатор может забронировать любое количество времени, но не
    менее 1 часа.
    <br />
    2. Бронирование одного и того же кабинета одним Арендатором с 30-минутным
    перерывом между бронированиями запрещено.
    <br />
    3. Бронирование осуществляется через сайт.
    <br />
    4. Оплата бронирования производится Арендатором в течение суток после
    окончания забронированного времени. Оплата может быть выполнена либо
    списанием средств с абонемента, либо безналичным переводом на реквизиты
    Арендодателя.
    <br />
    <br />
    <br />
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="left">
      Правила отмены бронирования
    </Typography>
    5. Средства возвращаются за вычетом фактически понесённых Арендодателем
    расходов в следующих случаях: <br />
    100% — при отмене за 24 часа и более до начала бронирования на карту или на
    счёт профиля (по выбору Арендатора), если отменяется бронирование дневного
    времени (до 17:00). <br />
    100% — при отмене за 48 часов и более до начала бронирования на карту или на
    счёт профиля (по выбору Арендатора), если отменяется бронирование вечернего
    времени (начиная с 17:00). <br />
    Если Арендатор создал заказ менее чем за 24 часа до 17:00 или менее чем за
    48 часов после 17:00, отмена заказа осуществляется бесплатно. При отмене
    бронирования аренды на 4 часа и более в один день отмена должна быть
    произведена Арендатором не менее чем за 96 часов до начала бронирования.
    Перенос бронирования в течение одного календарного дня на другое свободное
    время или в другое свободное помещение осуществляется бесплатно. Правила
    отмены действуют независимо от причин отмены.
    <br />
    <br />
    <span style={{ textDecoration: "underline", fontWeight: 600 }}>
      Очередь.
    </span>{" "}
    Арендатор может выбрать время, уже забронированное другим пользователем, и
    встать в очередь на это время в конкретном помещении. В этом случае бронь
    будет отображаться красным цветом. Если выбранное время в помещении
    освободится, Арендатор получит соответствующее уведомление. В случае, если
    бронь остаётся актуальной на момент получения уведомления, Арендатор должен
    подтвердить бронирование в течение 3 (трёх) часов с момента получения
    уведомления.
    <br />
    <br />
    <br />
    6. При отмене бронирования с возвратом средств на банковскую карту из суммы
    возврата удерживается сумма фактически понесённых Арендодателем расходов,
    включая комиссию платёжной системы. <br />
    Арендодатель не несет ответственности за сроки зачисления средств на карту
    Арендатора. <br />
    7. Отмена бронирования осуществляется через сайт. Для этого Арендатор должен
    кликнуть по строке своего бронирования на странице «Мои бронирования» или в
    расписании кабинета и перейти по ссылке для отмены бронирования. Отмену
    необходимо подтвердить нажатием на кнопку на странице отмены, и только после
    этого произойдёт реальная отмена.
    <br />
    <br />
    <br />
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="left">
      Правила переноса времени бронирования
    </Typography>
    <br />
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="left">
      Правила использования помещений в дополнительное время (сверх
      предварительно забронированного)
    </Typography>
    8. Арендатор может без штрафных санкций заходить в забронированное помещение
    за 15 минут до начала бронирования, при условии, что помещение в это время
    свободно. <br />
    9. Если в помещении есть бронирование другого Арендатора, следующего сразу
    после текущего, Арендатор должен покинуть помещение не позже, чем за 5 минут
    до начала следующего бронирования. При этом Арендатор самостоятельно
    отслеживает актуальную информацию о свободном времени выбранного или
    используемого помещения. <br />
    10. В случае, если из-за задержки Арендатора в помещении сверх
    забронированного им времени следующий Арендатор не смог вовремя попасть в
    помещение, Арендатору выставляется счёт на оплату компенсации за потерянное
    другим Арендатором время по текущему тарифу. Если бронирование начинается в
    момент окончания предыдущего, тарификация времени задержки начинается за 2
    минуты до начала следующего бронирования. <br />
    11. В случае неоднократного нарушения временных границ, которое приводит к
    неудобствам для других Арендаторов, администрация вправе отказаться от
    дальнейшего сотрудничества и прекратить действие настоящего договора. <br />
    12. Арендатор может покинуть помещение на 15 минут позже забронированного
    времени без штрафных санкций, при условии, что сразу после него помещение
    свободно. <br />
    13. Если Арендатору требуется задержаться в помещении с клиентом более чем
    на 5 минут, или без клиента — более чем на 15 минут, при условии, что
    помещение свободно, необходимо продлить бронирование через страницу Сайта
    для управления бронированием. В противном случае может быть выставлен штраф,
    включающий полную оплату времени, использованного сверх забронированного.{" "}
    <br />
    14. Если Арендатор по каким-либо причинам не предупреждает о задержке в
    помещении сверх забронированного времени (более чем на бесплатные 5 минут),
    штраф составит 400 рублей, если задержка не превышает 30 минут после
    окончания забронированного времени, и 2500 рублей, если задержка превышает
    30 минут. Для оплаты штрафа Арендатору будет выставлен счёт. <br />
    При этом возможность дальнейшего бронирования будет временно ограничена до
    оплаты этого счёта. <br />
    <br />
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="left">
      Правила поведения в арендуемых помещениях и прилагающих к ним территориям
    </Typography>
    15. Курение сигарет, кальянов, систем нагревания табака и табачных смесей, а
    также использование электронных сигарет на территории всех центров строго
    запрещено, включая помещения кабинетов. <br />
    16. Запрещается размещение любых рекламных материалов как снаружи, так и
    внутри арендованного кабинета. <br />
    17. Запрещается менять или закрывать таблички рядом с кабинетами, а также
    размещать свои таблички снаружи. <br />
    18. Для Арендатора и его клиентов в холле могут быть предусмотрены сидячие
    места. <br />
    19. Арендатор обязан соблюдать тишину за пределами кабинета. Разговоры по
    телефону разрешены внутри кабинета и за пределами центра аренды. Запрещается
    вводить код или пытаться войти в кабинет до начала разрешённого времени
    входа, установленного правилами, являющимися приложением к настоящему
    договору. <br />
    20. Запрещается принимать пищу на этаже за пределами кабинета. <br />
    21. Арендатору и его гостям запрещается употреблять алкогольные напитки на
    всей территории центра аренды, а также находиться в центре аренды в
    состоянии алкогольного или наркотического опьянения. <br />
    <br />
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="left">
      Правила поведения в помещениях
    </Typography>
    22. Перед началом работы Арендатору рекомендуется установить на сменной
    табличке (если она предусмотрена) положение «Занято». После окончания работы
    следует установить положение «Свободно».
    <br />
    23. Арендатор обязуется соблюдать требования пожарной безопасности. Строго
    запрещается пользоваться открытым огнём, включая свечи, ароматические
    палочки, спички, фейерверки, петарды и другие подобные предметы.
    <br />
    24. Запрещается использовать ароматические свечи и благовония.
    <br />
    25. Помещение после использования Арендатором должно быть оставлено в таком
    же состоянии, как оно было до его визита:
    <br />
    Арендатор обязан выбросить в корзину оставшийся мусор (одноразовые стаканы,
    салфетки, пакетики из-под чая и кофе и т. п.). Категорически запрещается
    выбрасывать стаканы, наполненные жидкостью, в ведро. Если Арендатор или его
    клиент не допил чай, кофе или воду, остатки жидкости должны быть вылиты в
    раковину одного из туалетов на этаже перед тем, как выбросить стакан.
    <br />
    Перед уходом Арендатор обязан проветрить кабинет, убедиться, что все окна
    закрыты, а также отключить кондиционер и освещение.
    <br />
    Арендатор обязан очистить использованные доски и маркерные поверхности.
    <br />
    Арендатор обязан вернуть мебель на место, если её переставляли.
    <br />
    Арендатор обязан вернуть инвентарь на место, где он находился при приёмке
    кабинета, за исключением случаев явного нарушения предыдущими арендаторами.
    <br />
    Даже если сразу после Арендатора в кабинет должен войти следующий Арендатор,
    он обязан закрыть дверь. Следующий Арендатор должен ввести свой код на замке
    самостоятельно.
    <br />
    В случае жалобы от администратора или следующего арендатора на несоблюдение
    данных правил, Арендатор может быть оштрафован на 50 рублей за каждый
    нарушенный пункт.
    <br />
    26. Если Арендатор пришёл в неубранный кабинет, он обязан сообщить об этом
    администрации, отправив фотографию проблемы через любой доступный канал
    связи.
    <br />
    27. В случае жалобы от администратора или следующего арендатора на
    беспорядок в кабинете после использования Арендатором, ему будет выставлен
    счёт на компенсацию уборки.
    <br />
    28. Арендатору запрещается создавать шум в кабинетах. В случае жалобы от
    администратора или другого арендатора на громкий шум, при наличии
    подтверждения факта обращения арендатора с просьбой не шуметь, Арендатору
    будет выставлен штраф.
    <br />
    29. В случае нарушения настоящих правил и других правил, являющихся
    приложением к настоящему договору, администрация вправе отказаться от
    дальнейшего сотрудничества с Арендатором.
    <br />
    <br />
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="left">
      Правила аренды дополнительного оборудования
    </Typography>
    30. Перед использованием оборудования Арендатор обязан убедиться в его
    исправности и сообщить Арендодателю обо всех повреждениях, которые могут
    повлиять на его использование или внешний вид.
    <br />
    31. После использования Арендатор обязан привести оборудование в порядок.
    Массажные столы следует протереть, сложить и упаковать в чехол.
    <br />
    32. Все повреждения оборудования, потребовавшие платного ремонта или замены,
    могут быть отнесены на счёт последнего арендатора, не сообщившего о
    проблемах. Стоимость ремонта или замены может быть удержана из авансовых
    средств Арендатора на его внутреннем счёте.
    <br />
    <br />
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="left">
      Прочие условия
    </Typography>
    33. Перед уходом Арендатор обязан убедиться в том, что дверь помещения
    закрыта и замок заперт. Арендатор несёт материальную ответственность за
    порчу и хищение имущества и интерьера помещения, если в помещение проникли
    посторонние лица, воспользовавшиеся незапертой дверью.
    <br />
    34. Арендатор несёт ответственность за соблюдение настоящих Правил всеми
    лицами, которых он допустил на территорию помещения своими активными
    действиями (например, сообщив код, открыв дверь и т. п.).
    <br />
    35. Арендатор несёт материальную ответственность за порчу имущества и
    интерьера помещения как со стороны себя, так и со стороны любых лиц, которых
    он допустил на территорию помещения своими активными действиями (например,
    сообщив код, открыв дверь и т. п.).
    <br />
    36. Арендатору запрещается приводить в помещение любые животные (включая
    собак, кошек, земноводных, пресмыкающихся, насекомых, членистоногих, рыб и
    других).
    <br />
    37. Арендатору запрещается выносить за пределы помещений канцелярские
    принадлежности — чистые листы бумаги, планшеты для бумаги, ручки, карандаши,
    маркеры, фломастеры, чистую бумагу для флипчарта и т. п., расходные
    материалы — упаковки сливок, пакетики чая, кофе-капсулы, а также предметы
    гигиены — крем для рук, термальную воду и т. п. Если рядом с расходными
    материалами размещено указание о необходимости их оплаты, Арендатор обязан
    внести оплату за использованные материалы согласно этим указаниям.
    <br />
    38. Арендатор несёт самостоятельную ответственность за соответствие
    деятельности, проводимой в данном помещении, нормам российского
    законодательства и Гражданского кодекса РФ.
    <br />
    39. В случае неоплаченных задолженностей по дополнительному времени, штрафам
    или компенсации администрация оставляет за собой право приостановить
    сотрудничество до момента полного погашения задолженности.
    <br />
    30. Все претензии и оспаривания по назначенным штрафам принимаются в течение
    3 рабочих дней с момента выставления штрафа. В этот срок не включается день
    выставления штрафа.
    <br />
  </Typography>
);
