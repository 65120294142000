import React, { useRef, useMemo, useState, useEffect, Suspense, useLayoutEffect } from "react";
import moment, { Moment } from "moment";
import {
    CalendarWrapper,
    Chevron,
    Header,
    Grid,
    GridItem,
    Hour,
    Minut,
    DateWrapper,
    DayBorder,
} from "./style";
import { Flex } from "../common/Styled/Flex";
import { Typography } from "../common/Styled/Typography";
import { COLORS } from "../../utils/constants/colors";
import { CalendarChevron, LoaderIcon } from "../../assets/icons";
import { IOffice } from "../../types/interfaces/office";
import { SetStateType, VoidFuncType } from "../../types/common";
import { IBooking } from "../../types/interfaces/booking";
import useResolution from "../../hooks/useResolution";

const DrawOverlay = React.lazy(() => import('./DrawOverlay'))

interface ICalendarProps {
    data: IBooking[],
    view: 7 | 1,
    schedule: { [key: string]: string },
    step: 1 | 2,
    calendarPrams?: IOffice,
    setBookings: VoidFuncType,
    setDates: SetStateType<{
        dateAfter: string;
        dateBefore: string;
    }>,
    setButtonText?: SetStateType<string>,
    modalOpen?: boolean
    setModalOpen?: SetStateType<boolean>,
    notDraw?: boolean,
    selectedBooking?: IBooking | null,
    setLocalTime?: SetStateType<{ time: string, date: string }>,
    chengedGroup?: number | null,
    toNow: boolean
    setToNow: SetStateType<boolean>
    notAdaptive?: boolean
    loading: boolean
    noQueue: boolean
    showNameUser?: boolean
    bookingClickHandler?: (booking: IBooking) => void,
    showMyBooking?: boolean
    activeId?: number
}

const Calendar = ({
    data,
    view,
    schedule,
    step,
    calendarPrams,
    setBookings,
    setDates,
    setButtonText,
    setModalOpen,
    modalOpen,
    notDraw,
    selectedBooking,
    setLocalTime,
    chengedGroup,
    toNow,
    setToNow,
    notAdaptive,
    loading,
    noQueue,
    showNameUser,
    bookingClickHandler,
    showMyBooking,
    activeId
}: ICalendarProps) => {
    const [week, setWeek] = useState(() => {
        if (selectedBooking) return moment(selectedBooking.date, 'YYYY-MM-DD').isoWeek() - moment().isoWeek()
        return 0
    });
    const [day, setDay] = useState(0)
    const [ceilSize, setCeilSize] = useState({
        width: 0,
        height: 0
    })
    const ref = useRef<HTMLDivElement>(null);
    const calendar = useRef<HTMLDivElement>(null);
    const timeRef = useRef<HTMLDivElement>(null)
    const isMiniScreen = useResolution(1200)
    const isMobile = useResolution(1000)
    const [calendarHieght, setCalendarHeight] = useState(400)

    const { hoursCount, startHour } = useMemo(() => {
        const times = [...new Set(
            Object.values(schedule).reduce((acc: string[], item) => [...acc, ...item.split('-')], [])
                .map(time => {
                    return time.slice(0, 2)
                })
        )];
        const sortedTimes = times.sort((a, b) => +a - +b);
        return { hoursCount: +sortedTimes[sortedTimes.length - 1] - +sortedTimes[0], startHour: sortedTimes[0] };
    }, [schedule])

    useLayoutEffect(() => {
        const changeSize = () => {
            if (calendar.current && timeRef.current) {
                const fieldTop = calendar.current?.getBoundingClientRect().top
                const height = window.innerHeight - fieldTop - (isMobile ? 90 : 140)
                !notAdaptive && height >= 200 && setCalendarHeight(height)

                setCeilSize(prevState => {
                    if (calendar.current && timeRef.current) {
                        const { width } = calendar.current.getBoundingClientRect()
                        return {
                            width: (width - (timeRef.current?.clientWidth + 15)) / 7,
                            height: notAdaptive ? 400 / hoursCount : height >= 200 ? height / hoursCount : prevState.height ? prevState.height : calendarHieght / hoursCount
                        }
                    }
                    return prevState
                })
            }
        }

        changeSize()

        window.addEventListener('resize', changeSize)
        return () => {
            window.removeEventListener('resize', changeSize)
        }
    }, [calendar, week, day, notAdaptive, data, timeRef, view, isMobile, hoursCount, calendarHieght])

    useEffect(() => {
        if (selectedBooking) {
            const { date } = selectedBooking
            if (view === 1) {
                const day = moment(date).isoWeek() >= moment().isoWeek() ? +moment(date).format('DDDD') - +moment().format('DDDD')
                    :
                    +moment().endOf('year').format('DDDD') - +moment().format('DDDD') + +moment(date).format('DDDD')
                setDay(day)
                return
            }
            setWeek(moment(date).isoWeek() >= moment().isoWeek() ? moment(date).isoWeek() - moment().isoWeek() : moment(moment().endOf('year'), 'DD.MM.YYYY').isoWeek() - moment().isoWeek() + moment(date).isoWeek())
        }
    }, [selectedBooking, view])

    useEffect(() => {
        if (toNow) {
            setWeek(0)
            setDay(0)
        }
        setToNow(false)
    }, [toNow, setToNow])

    const currentWeek = useMemo(() => {
        const date = moment().add(week, 'weeks');
        const start = moment(date).startOf('isoWeek');
        const end = moment(date).endOf('isoWeek');

        const days = [];
        let day = start;
        while (day <= end) {
            days.push({ name: moment(day.toDate()).format('dd'), date: moment(day.toDate()) });
            day = day.clone().add(1, 'day');
        }
        return days;
    }, [week, view]);

    const currentDay = useMemo(() => {
        return { name: moment().add(day, 'day').format('dd'), date: moment().add(day, 'day') }
    }, [day, view])

    useEffect(() => {
        if (view === 1) {
            const date = currentDay.date.format('YYYY-MM-DD')
            return setDates({ dateAfter: date, dateBefore: date })
        }
        const dateOnCurrentDate = (date: Moment): string => moment(date).isBefore(moment(), 'day') ? dateOnCurrentDate(moment(date).add(1, 'day')) : moment(date).format('YYYY-MM-DD')
        const dateAfter = currentWeek[0].date.format('YYYY-MM-DD')
        const dateBefore = currentWeek[currentWeek.length - 1].date.format('YYYY-MM-DD')
        setDates({ dateAfter, dateBefore })
    }, [day, week, view])

    return (
        <Flex width="100%" align="flex-start" justify="flex-start">
            <CalendarWrapper>
                <Flex justify="flex-end" width="100%">
                    <Header width={`calc(100% - (${timeRef.current ? timeRef.current?.offsetWidth + 15 : 40}px))`}
                        justify="flex-start">
                        {!((view === 7 && week === 0) || (view === 1 && day === 0)) && <Chevron isPrev={true}
                            onClick={() => view === 7 ? setWeek(week - 1) : setDay(day - 1)}><CalendarChevron /></Chevron>}
                        {view === 7 ?
                            currentWeek.map((item, index) => (
                                <Flex width="100%" key={index}>
                                    <DayBorder active={item.date.isSame(moment(), 'd')} direction="column">
                                        <Typography color={COLORS.darkSteel} weight={500}>
                                            {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                                        </Typography>
                                        <DateWrapper>
                                            <Typography color={COLORS.darkSteel} weight={500}>
                                                {item.date.format('DD')}
                                            </Typography>
                                            {!isMiniScreen && <span>&nbsp;</span>}
                                            <Typography color={COLORS.darkSteel} weight={500}>
                                                {isMiniScreen ? item.date.format('DD MMMM').split(' ')[1].slice(0, 3) + '.' : item.date.format('DD MMMM').split(' ')[1]}
                                            </Typography>
                                        </DateWrapper>
                                    </DayBorder>
                                </Flex>
                            ))
                            :
                            <Flex width="100%">
                                <DayBorder direction="column" active={currentDay.date.isSame(moment(), 'd')}>
                                    <Typography color={COLORS.darkSteel} weight={500}>
                                        {currentDay.name.charAt(0).toUpperCase() + currentDay.name.slice(1)}
                                    </Typography>
                                    <Typography color={COLORS.darkSteel} weight={500}>
                                        {currentDay.date.format('DD MMMM')}
                                    </Typography>
                                </DayBorder>
                            </Flex>
                        }
                        <Chevron isPrev={false}
                            onClick={() => view === 7 ? setWeek(week + 1) : setDay(day + 1)}><CalendarChevron /></Chevron>
                    </Header>
                </Flex>
                <Flex height={`${calendarHieght}px`} ref={calendar} margin="10px 0 0 0" align="flex-start" width="100%">
                    <Flex height="calc(100% + 15px)" justify="space-between" ref={timeRef} margin="0 15px 0 0"
                        direction="column">
                        {Array.from({ length: hoursCount + 1 }).map((item, index) => (
                            <Flex key={index} align="flex-start">
                                <Hour weight={500} size="16px" color={COLORS.darkSteel}
                                    margin="0 3px 0 0">{index + (+startHour)}</Hour>
                                <Minut weight={500} size="8px" color={COLORS.darkSteel}>00</Minut>
                            </Flex>
                        ))}
                    </Flex>
                    {loading ?
                        <Flex height="100%"
                            width={`calc(100% - ${timeRef.current ? timeRef.current.clientWidth + 15 : 40}px)`}>
                            <LoaderIcon />
                        </Flex>
                        :
                        <>
                            <Suspense fallback={
                                <Flex height="100%"
                                    width={`calc(100% - ${timeRef.current ? timeRef.current.clientWidth + 15 : 40}px)`}>
                                    <LoaderIcon />
                                </Flex>
                            }>
                                <Grid ref={ref} rows={hoursCount} margin="10px 0 0 0" view={view}>
                                    <DrawOverlay
                                        schedule={schedule}
                                        reservations={data}
                                        view={view}
                                        hoursCount={hoursCount}
                                        startHour={+startHour}
                                        step={step}
                                        currentWeek={currentWeek}
                                        currentDay={currentDay}
                                        ceilSize={ceilSize}
                                        calendarPrams={calendarPrams}
                                        setBookings={setBookings}
                                        modalOpen={modalOpen}
                                        setButtonText={setButtonText}
                                        setModalOpen={setModalOpen}
                                        notDraw={notDraw}
                                        selectedBooking={selectedBooking}
                                        setLocalTime={setLocalTime}
                                        chengedGroup={chengedGroup}
                                        noQueue={noQueue}
                                        showNameUser={showNameUser}
                                        bookingClickHandler={bookingClickHandler}
                                        showMyBooking={showMyBooking}
                                        activeId={activeId}
                                    />
                                    {Array.from({ length: hoursCount * view }).map((item, index) => (
                                        <GridItem border={!((index + 1) % 7 === 0)} view={view} key={index}></GridItem>
                                    ))}
                                </Grid>
                            </Suspense>
                        </>
                    }
                </Flex>
            </CalendarWrapper>
        </Flex>
    )
}

export default Calendar;