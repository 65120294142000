import { Typography } from "../../../../../components/common/Styled/Typography";

export const Article6 = () => (
  <Typography fontFamily="Montserrat" size="16px" align="justify">
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="center">
      Статья 6. Ответственность сторон
    </Typography>
    6.1. За невыполнение или ненадлежащее выполнение обязательств по настоящему
    договору стороны несут ответственность согласно действующему
    законодательству РФ.
    <br />
    6.2. Арендодатель не несет ответственности за сбои в работе сайта, возникшие
    не по вине Арендодателя.
    <br />
    6.3. Арендатор в случае порчи имущества Арендодателя, находящегося в
    помещении, обязуется возместить расходы Арендодателя на приобретение нового
    имущества, в случае невозможности его восстановления, очистки или ремонта, а
    также обязуется возместить стоимость очистки имущества, в случае его
    загрязнения по вине Арендатора. Арендатор возмещает расходы Арендодателя на
    основании требования, полученного от Арендодателя и в порядке, указанном в
    этом требовании.
    <br />
    <br />
  </Typography>
);
