import React from "react";
import { Container, Banner, Wrapper, Line, Handshake } from "./style";
import { Typography } from "../../../../components/common/Styled/Typography";
import banner from "../../../../assets/images/agreementBanner.png";
import handshake from "../../../../assets/images/handshake.png";
import { Button } from "../../../../components/common/Styled/Button";
import { useDispatch } from "react-redux";
import {
  setLogged,
  updateMineProfile,
} from "../../../../store/reducers/userReducer";
import { Flex } from "../../../../components/common/Styled/Flex";
import { AgreementDoc1 } from "./AgreementDoc1";
import { AgreementDoc2 } from "./AgreementDoc2";

const Agreement = () => {
  const dispatch = useDispatch();

  const agree = () => {
    dispatch(updateMineProfile({ data: { is_accept: true } }));
    dispatch(setLogged(true));
  };

  return (
    <Wrapper>
      <Banner src={banner} alt="agreement" />
      <Container direction="column" align="flex-start" justify="space-between">
        <Typography
          size="36px"
          fontFamily="Montserrat"
          align="center"
          weight={700}
        >
          Договор аренды
        </Typography>
        <AgreementDoc1 />
        <AgreementDoc2 />
        <Flex width="100%" margin="90px 0 0 0">
          <Line />
          <Handshake src={handshake} alt="handshake" />
          <Line />
        </Flex>
        <Button
          theme="grey"
          fontSize="16px"
          radius="6px"
          padding="8px 65px"
          weight={600}
          width="100%"
          margin="80px auto 60px"
          onClick={agree}
        >
          С ДОГОВОРОМ ОЗНАКОМИЛСЯ(ЛАСЬ), ПРИНИМАЮ УСЛОВИЯ СОТРУДНИЧЕСТВА
        </Button>
      </Container>
    </Wrapper>
  );
};

export default Agreement;
