import { Typography } from "../../../../../components/common/Styled/Typography";

export const Article7 = () => (
  <Typography fontFamily="Montserrat" size="16px" align="justify">
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="center">
      Статья 7. Порядок изменения, переноса сроков субаренды Помещения и
      возврата денежных средств
    </Typography>
    7.1. В случае отказа Арендатора от настоящего договора и/или от аренды
    помещения после размещения заказа на сайте и более чем за 2 (два) дня до
    даты аренды помещения, Арендодатель обязуется возвратить Арендатору денежные
    средства, уплаченные в качестве предоплаты, за вычетом суммы понесенных
    Арендодателем расходов в течение 10 (десяти) рабочих дней по реквизитам,
    указанным Арендатором. В этот срок не включается время, необходимое для
    обработки платежа банками и иными использованными для возврата средств
    платежными системами. В случае отказа Арендатора, оплатившего абонемент, от
    настоящего договора и/или от аренды помещения, Арендодатель осуществляет
    перерасчет стоимости использованного времени по стоимости аренды одного часа
    для лиц, не приобретших абонемент. <br />
    7.2. В случае если Арендатор не воспользовался помещением в оплаченные часы
    и не уведомил об этом Арендодателя, в порядке п. 7.1. настоящего Договора,
    денежные средства, уплаченные за указанные в заказе часы аренды помещения
    Арендатору, не возвращаются.
    <br />
    <br />
  </Typography>
);
