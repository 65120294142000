import { Typography } from "../../../../../components/common/Styled/Typography";

export const Article8 = () => (
  <Typography fontFamily="Montserrat" size="16px" align="justify">
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="center">
      Статья 8. Порядок разрешения споров
    </Typography>
    8.1. Все споры по настоящему Договору разрешаются путем переговоров. Для
    сторон обязателен претензионный порядок урегулирования споров.
    <br />
    8.2. Сторона, получившая претензию, обязана дать ответ на нее в течение 30
    (тридцати) дней с момента получения.
    <br />
    8.3. Претензии направляются по контактным данным Заказчика, указанным в
    Личном кабинете Заказчика, по контактным данным Агента, указанным в
    настоящем Договоре или на Сайте.
    <br />
    8.4. Споры и разногласия Сторон по Договору передаются на рассмотрение суд
    общей юрисдикции в соответствии с действующим законодательством Российской
    Федерации.
    <br />
    <br />
  </Typography>
);
