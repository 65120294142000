import React, { useState } from "react";
import styled from "styled-components";
import { Typography } from "../Styled/Typography";

const DropdownWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 32px;
`;

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
`;

const Arrow = styled.span<ArrowProps>`
  display: inline-block;
  transition: transform 0.3s ease;
  transform: rotate(${(props) => (props.open ? "180deg" : "0deg")});
`;

type PropsType = {
  header: string;
  children: React.ReactNode;
};

type ArrowProps = {
  open: boolean;
};

export const Dropdown = ({ header, children }: PropsType) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <DropdownWrapper>
      <DropdownHeader onClick={toggleDropdown}>
        <Typography size="24px">{header}</Typography>
        <Arrow open={isOpen}>▼</Arrow>
      </DropdownHeader>
      {isOpen && <> {children}</>}
    </DropdownWrapper>
  );
};
