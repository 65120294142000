import React from "react";
import { Typography } from "../../../../components/common/Styled/Typography";
import { CabinetPreviewWrapper, List, PhotoWrapper, Title } from "./style";
import { Flex } from "../../../../components/common/Styled/Flex";
import { COLORS } from "../../../../utils/constants/colors";
import { IOffice } from "../../../../types/interfaces/office";
import { Button } from "../../../../components/common/Styled/Button";
import { SetStateType } from "../../../../types/common";
import { setActiveCabinetId } from "../../../../store/reducers/bookingReducer";
import { useDispatch } from "react-redux";

interface IProps {
  show: boolean;
  data: IOffice;
  showCabinet: (id: number) => void;
  isMobile: boolean;
  showCalendar: SetStateType<boolean>;
}

const CabinetPreview = ({
  data,
  showCabinet,
  isMobile,
  showCalendar,
  show,
}: IProps) => {
  const {
    id,
    metric_area,
    max_peoples,
    parking,
    address,
    occupation_step,
    name,
    photos,
  } = data;
  const verificationStr = "234";
  const dispatch = useDispatch();
  const normalizeStr = (count: number) => {
    const indexStr = count.toString();
    return `${indexStr} ${
      verificationStr.includes(indexStr[indexStr.length - 1]) &&
      (+indexStr < 10 || +indexStr > 20)
        ? "человека"
        : "человек"
    }`;
  };

  const selectOffice = (id: number) => {
    showCabinet(id);
    dispatch(setActiveCabinetId(id));
  };

  return (
    <CabinetPreviewWrapper onClick={() => selectOffice(id)} show={show}>
      <Title
        show={show}
        className="title"
        weight="600"
        size="18px"
        lineHeight="21px"
      >
        {name}, {address}
      </Title>
      <Flex align="flex-start" justify="flex-start" margin="17px 0 0 0">
        <PhotoWrapper>
          {photos[0] && <img src={photos[0].photo} alt="Фото офиса" />}
        </PhotoWrapper>
        <Flex width="60%" align="flex-start" direction="column">
          <List>
            <Typography
              size="12px"
              fontFamily="Montserrat"
              color={COLORS.steel}
              weight="500"
            >
              {metric_area}
              <span>
                {" "}
                м<sup>2</sup>
              </span>
            </Typography>
            <Typography
              size="12px"
              fontFamily="Montserrat"
              color={COLORS.steel}
              weight="500"
            >
              {normalizeStr(max_peoples)}
            </Typography>
            <Typography
              size="12px"
              fontFamily="Montserrat"
              color={COLORS.steel}
              weight="500"
            >
              {parking} парковка
            </Typography>
            <Typography
              size="12px"
              fontFamily="Montserrat"
              color={COLORS.steel}
              weight="500"
            >
              Мин {occupation_step === 1 ? "1 час" : "30 минут"}
            </Typography>
          </List>
          <Typography size="18px" weight="700">
            {data?.price_category?.price}₽&nbsp;/&nbsp;час
          </Typography>
          <Typography
            size="12px"
            fontFamily="Montserrat"
            color={COLORS.steel}
            weight="500"
          >
            {data?.price_category?.name}
          </Typography>
          {isMobile && (
            <Button
              onClick={() => showCalendar(true)}
              radius="5px"
              theme="beige"
              width="100%"
              height="24px"
              weight="500"
              fontSize="12px"
              lineHeight="14px"
              margin="10px 0 0 0"
            >
              Открыть расписание
            </Button>
          )}
        </Flex>
      </Flex>
    </CabinetPreviewWrapper>
  );
};

export default CabinetPreview;
