import { Formik, Form, Field } from "formik";
import React, { useEffect, useState } from "react";
import FormikInput from "../../../../../components/common/FormikElements/Input";
import FormikSelect from "../../../../../components/common/FormikElements/Select";
import { Button } from "../../../../../components/common/Styled/Button";
import { Flex } from "../../../../../components/common/Styled/Flex";
import { Typography } from "../../../../../components/common/Styled/Typography";
import { VoidFuncType } from "../../../../../types/common";
import { ISubscription } from "../../../../../types/interfaces/subscription";
import {
  addSubscriptions,
  changeSubscriptions,
  getPriceCategories,
} from "../../../../../utils/api/routes";
import { AddSubscriptionWrapper } from "./style";
import * as Yup from "yup";
import {
  REQUIRED_POSITIVE_NUMBER,
  REQUIRED_STRING,
} from "../../../../../utils/formik/validation";
import ErrorHandler from "../../../../../components/Error";
import { useErrorHandler } from "../../../../../hooks/useErrorHandler";
import { showAlert } from "../../../../../utils/showAlert";

type OptionType = { value: number; name: string };

interface IProps {
  closeModal: VoidFuncType;
  subscription: ISubscription;
  updateComponent: VoidFuncType;
}

function AddSubscriptionsPopup({
  closeModal,
  subscription,
  updateComponent,
}: IProps) {
  const [priceCategories, setPriceCategories] = useState<Array<OptionType>>([]);
  const { show, active, hidden, text } = useErrorHandler();
  const initial = {
    name: subscription ? subscription.name : "",
    count: subscription ? subscription.count : "",
    hours_all: subscription ? subscription.hours_all : "",
    hour_one: subscription ? subscription.hour_one : "",
    price_category: subscription ? subscription.price_category?.id : "",
  };

  useEffect(() => {
    getPriceCategories().then((res) =>
      setPriceCategories(
        res.data.map((item) => ({
          value: item.id,
          name: item.name + (item.is_group ? "(групповая)" : ""),
        }))
      )
    );
  }, []);

  const schema = Yup.object({
    name: REQUIRED_STRING,
    count: REQUIRED_POSITIVE_NUMBER,
    hours_all: REQUIRED_POSITIVE_NUMBER,
    hour_one: REQUIRED_POSITIVE_NUMBER,
    price_category: REQUIRED_STRING,
  });

  const submit = (values: typeof initial) => {
    const body = {
      ...values,
      count: +values.count,
      hours_all: +values.hours_all,
      hour_one: +values.hour_one,
      price_category: +values.price_category,
    };
    subscription
      ? changeSubscriptions(body, subscription.id)
          .then(() => {
            closeModal();
            updateComponent();
            showAlert("success", "Абонемент успешно обновлен");
          })
          .catch((e) => showAlert("error", "Не удалось обновить абонемент"))
      : addSubscriptions(body)
          .then(() => {
            closeModal();
            updateComponent();
            showAlert("success", "Абонемент успешно добавлен");
          })
          .catch((e) => showAlert("error", "Не удалось добавить абонемент"));
  };

  return (
    <AddSubscriptionWrapper>
      <ErrorHandler active={active} hidden={hidden} text={text} />
      <Flex padding="10px 0" border="1px solid #DECEB7">
        <Typography weight="700" size="18px" lineHeight="21px">
          {subscription ? "Редактирование абонемента" : "Создание абонемента"}
        </Typography>
      </Flex>
      <Flex padding="20px" width="100%">
        <Formik
          initialValues={initial}
          onSubmit={(values) => submit(values)}
          validationSchema={schema}
        >
          <Form>
            <Flex gap="40px">
              <Field
                component={FormikInput}
                name="name"
                placeholder="Название"
                label="Название"
                direction="column"
                schema={schema}
              />
              <Field
                type="number"
                component={FormikInput}
                name="count"
                placeholder="Количество часов"
                label="Количество часов"
                direction="column"
                schema={schema}
              />
            </Flex>
            <Flex gap="40px" margin="15px 0 0 0">
              <Field
                type="number"
                component={FormikInput}
                name="hour_one"
                placeholder="Стоимость часа в абонементе, ₽"
                label="Стоимость часа в абонементе, ₽"
                direction="column"
                schema={schema}
              />
              <Field
                type="number"
                component={FormikInput}
                name="hours_all"
                placeholder="Стоимость абонемента, ₽"
                label="Стоимость абонемента, ₽"
                direction="column"
                schema={schema}
              />
            </Flex>
            <Flex width="calc(50% - 20px)" margin="15px 0 0 0">
              <Field
                component={FormikSelect}
                options={priceCategories}
                name="price_category"
                label="Ценовая категория"
                direction="column"
                schema={schema}
              />
            </Flex>
            <Flex justify="space-between" margin="20px 0 0 0">
              <Button
                theme="beige"
                radius="21px"
                width="155px"
                height="40px"
                type="button"
                weight="600"
                lineHeight="17px"
                onClick={() => closeModal()}
              >
                Отменить
              </Button>
              <Button
                theme={subscription ? "green" : "grey"}
                radius="21px"
                width="155px"
                height="40px"
                type="submit"
                weight="600"
                lineHeight="17px"
              >
                {subscription ? "Сохранить" : "Создать"}
              </Button>
            </Flex>
          </Form>
        </Formik>
      </Flex>
    </AddSubscriptionWrapper>
  );
}

export default AddSubscriptionsPopup;
