import { Typography } from "../../../../../components/common/Styled/Typography";

export const Article2 = () => (
  <Typography fontFamily="Montserrat" size="16px" align="justify">
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="center">
      Статья 2. Понятия, используемые в настоящем договоре
    </Typography>
    2.1. Для целей настоящего договора используются следующие понятия:
    <br />
    • Сайт https://psy-office.pro (далее — «сайт») — это система связанных между
    собой веб-страниц, обеспечивающая возможность аренды жилого или нежилого
    помещения, сбора данных для передачи помещения в аренду, предоставления
    выбора способов оплаты аренды и т.д.
    <br />
    • Заказ на аренду — информация, отправленная Арендатором Арендодателю через
    раздел сайта «Расписание», расположенный по адресу:
    https://psy-office.pro/offices, с целью аренды жилого или нежилого помещения
    и заключения договора аренды с Арендодателем.
    <br />
    • Арендатор — физическое лицо, разместившее заказ с помощью сайта,
    принимающее условия настоящего договора с целью проведения консультационных
    услуг, сеансов психотерапии, и иных согласованных с арендодателем сеансов
    при ведении деятельности на территории арендодателя.
    <br />
    • Посетитель сайта — лицо, пришедшее на сайт без цели размещения Заказа.
    <br />
    • Помещение — жилое или нежилое помещение, передаваемое Арендодателем
    Арендатору на условиях настоящего Договора, расположенное по следующим
    адресам:
    <br />
    • г. Санкт-Петербург, 5-я линия ВО 54 лит. А, офисы: 8-Н, 58, 63;
    <br />
    • г. Санкт-Петербург, набережная реки Мойки, 67-69, офис: 4;
    <br />
    • г. Санкт-Петербург, ул. Петропавловская д. 6, кв. 14.
    <br />
    Дополнительно появляющиеся адреса у Арендодателя для бронирования
    Арендатором будут размещены на странице раздела сайта «Расписание».
    <br />
    • Гость — физическое лицо — клиент арендатора, его визитер или иное лицо,
    допущенное в помещения, являющееся предметом аренды по настоящему договору
    арендатором.
    <br />• Абонемент — форма аренды, при которой Арендатор оплачивает заранее
    определенное количество часов аренды помещения. Абонементы размещены на
    странице https://psy-office.pro/purchases и действуют в течение 12 месяцев с
    момента приобретения. Абонемент может быть использован в любое время в
    пределах оплаченного количества часов, если это согласуется с расписанием и
    не нарушает условия настоящего договора.
    <br />
    <br />
  </Typography>
);
