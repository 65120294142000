import React from "react";
import { Button } from "../../../../../components/common/Styled/Button";
import { Flex } from "../../../../../components/common/Styled/Flex";
import { Typography } from "../../../../../components/common/Styled/Typography";
import ErrorHandler from "../../../../../components/Error";
import { useErrorHandler } from "../../../../../hooks/useErrorHandler";
import { VoidFuncType } from "../../../../../types/common";
import { IPriceCategory } from "../../../../../types/interfaces/priceCategories";
import { deletePriceCategory } from "../../../../../utils/api/routes";
import { showAlert } from "../../../../../utils/showAlert";
import { DeletePriceCategoryPopupWrapper, CategoryName } from "./style";

interface IProps {
  id: number;
  updateComponent: (arg: string) => void;
  closeModal: VoidFuncType;
  activePriceCategory: IPriceCategory;
}

function DeletePriceCategoryPopup({
  id,
  updateComponent,
  closeModal,
  activePriceCategory,
}: IProps) {
  const { show, active, hidden, text } = useErrorHandler();

  const deletePriceCAtegoryById = async () => {
    deletePriceCategory(activePriceCategory.id)
      .then(() => {
        updateComponent("");
        closeModal();
        showAlert("success", "Ценовая категория успешно удалена");
      })
      .catch((e) => {
        closeModal();
        showAlert("error", "Не удалось удалить ценовую категорию");
      });
  };

  return (
    <DeletePriceCategoryPopupWrapper>
      <ErrorHandler active={active} hidden={hidden} text={text} />
      <Flex padding="10px 0" border="1px solid #DECEB7">
        <Typography weight="700" size="18px" lineHeight="21px">
          Удалить ценовую категорию
        </Typography>
      </Flex>
      <Flex padding="10px" direction="column">
        <Typography size="18px" lineHeight="21px" align="center">
          <CategoryName>
            {activePriceCategory?.name}
            <br />
          </CategoryName>
          Вы уверены, что хотите удалить данную ценовую категорию?
        </Typography>
        <Flex padding="10px 10px" direction="row">
          <Button
            margin="0 10px 0 0"
            onClick={closeModal}
            theme="grey"
            width="155px"
            height="40px"
            radius="21px"
            weight="600"
            lineHeight="17px"
          >
            Отмена
          </Button>
          <Button
            onClick={deletePriceCAtegoryById}
            theme="grey"
            width="155px"
            height="40px"
            radius="21px"
            weight="600"
            lineHeight="17px"
          >
            Да, я уверен(a)
          </Button>
        </Flex>
      </Flex>
    </DeletePriceCategoryPopupWrapper>
  );
}

export default DeletePriceCategoryPopup;
