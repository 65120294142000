import { AgreementAttachment } from "./AgreementAttachment";
import { AgreementIntro } from "./AgreementIntro";
import { Article1 } from "./Article1";
import { Article10 } from "./Article10";
import { Article11 } from "./Article11";
import { Article2 } from "./Article2";
import { Article3 } from "./Article3";
import { Article4 } from "./Article4";
import { Article5 } from "./Article5";
import { Article6 } from "./Article6";
import { Article7 } from "./Article7";
import { Article8 } from "./Article8";
import { Article9 } from "./Article9";
import { Dropdown } from "../../../../../components/common/DropDown/DropDown";

export const AgreementDoc2 = () => (
  <Dropdown header="Договор аренды №2">
    <>
      <AgreementIntro />
      <Article1 />
      <Article2 />
      <Article3 />
      <Article4 />
      <Article5 />
      <Article6 />
      <Article7 />
      <Article8 />
      <Article9 />
      <Article10 />
      <Article11 />
      <AgreementAttachment />
    </>
  </Dropdown>
);
