import { Field, Form, Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Label } from "../../../../components/common/FormikElements/Input/style";
import { Button } from "../../../../components/common/Styled/Button";
import { Flex } from "../../../../components/common/Styled/Flex";
import { Grid } from "../../../../components/common/Styled/Grid";
import { IOffice } from "../../../../types/interfaces/office";
import { Typography } from "../../../../components/common/Styled/Typography";
import { RootState } from "../../../../store/reducers/rootReducer";
import { COLORS } from "../../../../utils/constants/colors";
import { Tab } from "../../Users/AddPsyhoPopup/style";
import { AddCabinetWrapper, ModalPhoto } from "./style";
import {
  REQUIRED_STRING,
  RQUIRED_NUMBER,
} from "../../../../utils/formik/validation";
import { OptionType } from "../../../../types/common";
import { LoaderIcon } from "../../../../assets/icons";
import { getCorrectFieldLabel } from "../../../../utils/formik/getCorrectFieldLabel";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";
import {
  addPhotoForCabinet,
  deletePhotoOnCabinet,
  getOffice,
  getPriceCategories,
  getSelectParams,
  officeCreate,
  changeOffice,
} from "../../../../utils/api/routes";

import Select from "../../../../components/common/FormElements/Select";
import FormikInput from "../../../../components/common/FormikElements/Input";
import FormikSelect from "../../../../components/common/FormikElements/Select";
import FormikTextArea from "../../../../components/common/FormikElements/TextArea";
import Modal from "../../../../components/Modal";
import ErrorHandler from "../../../../components/Error";
import DaySelects from "./DaySelects";
import FotoInputs from "./FotoInputs";
import * as Yup from "yup";
import { showAlert } from "../../../../utils/showAlert";

type OptionManyType = { id: number; name: string; active: boolean };

export type SelectWorkDayType = {
  id: number;
  timeBefore: number;
  timeAfter: number;
  day: number;
  options: Array<OptionType>;
};
export type PhotoType = {
  file?: File;
  url: string | ArrayBuffer | null;
  id: number;
};

const AddCabinetPage = () => {
  const history = useHistory();
  const location = useLocation();

  const isEditing = location.pathname.includes("offices/change");
  const { show, active, hidden, text } = useErrorHandler();
  const { id: officeId } = useParams<{ id: string }>();
  const { id: userId } = useSelector((state: RootState) => state.user.profile);

  const [office, setOffice] = useState<IOffice | null>(null);
  const [cityId, setCityId] = useState<number | null>(null);
  const [cities, setCities] = useState<Array<OptionType>>([]);
  const [metros, setMetros] = useState<
    Array<{ id: number; name: string; city?: number }>
  >([]);
  const [status, setStatus] = useState(true);
  const [priceCategories, setPriceCategories] = useState<
    Array<OptionType & { payment_system: number }>
  >([]);
  const [photoCabinet, setPhotoCabinet] = useState<Array<PhotoType>>([]);
  const [photoRoad, setPhotoRoad] = useState<Array<PhotoType>>([]);
  const [activePhoto, setActivePhoto] = useState<PhotoType | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(office?.occupation_step || (1 as 1 | 2));
  const [timesSelect, setTimesSelect] = useState<Array<SelectWorkDayType>>([]);
  const [firstRender, setFerstRender] = useState(false);
  const [optionsMetros, setOptionsMetros] = useState<Array<OptionManyType>>([]);
  const [activeMetros, setActiveMetros] = useState("");
  const [loading, setLoading] = useState(false);
  const [photoDelete, setPhotoDelete] = useState<
    Array<{ type: string; id: number }>
  >([]);
  const [errors, setErrors] = useState({
    schedule: false,
    city: false,
    photo: false,
    photoRoad: false,
    metro: false,
  });

  const inputs = [
    {
      id: 1,
      label: "Название",
      name: "name",
      components: FormikInput,
      type: "text",
    },
    {
      id: 2,
      label: "Пароль от кабинета",
      name: "password",
      components: FormikInput,
      type: "text",
    },
    { id: 3, name: "city" },
    {
      id: 4,
      label: "Метраж, м²",
      name: "metric_area",
      components: FormikInput,
      type: "number",
    },
    { id: 5, name: "metro" },
    {
      id: 6,
      label: "Парковка",
      name: "parking",
      components: FormikInput,
      type: "text",
    },
    {
      id: 7,
      label: "Адрес",
      name: "address",
      components: FormikInput,
      type: "text",
    },
    {
      id: 8,
      label: "Количество человек",
      name: "max_peoples",
      components: FormikInput,
      type: "number",
    },
  ] as const;

  const initial = {
    name: "",
    password: "",
    city: "",
    metric_area: "",
    metro: "",
    parking: "",
    address: "",
    max_peoples: 1,
    description: "",
    price_category: "",
  };

  useEffect(() => {
    const err = {} as { [key: string]: boolean };
    const addNewValue = (name: string, value: boolean) => (err[name] = value);

    cityId && addNewValue("city", false);
    photoRoad.length && addNewValue("photoRoad", false);
    photoCabinet.length && addNewValue("photo", false);
    timesSelect.length && addNewValue("schedule", false);
    optionsMetros.filter((item) => item.active).length &&
      addNewValue("metro", false);

    setErrors((prevState) => ({ ...prevState, ...err }));
  }, [cityId, photoRoad, photoCabinet, timesSelect, optionsMetros]);

  useEffect(() => {
    const selectedMetros =
      metros.length &&
      metros.map((item) => {
        const el = office && office.metro.find((metro) => metro.id === item.id);
        if (el) {
          return { id: item.id, name: item.name, active: true };
        }
        return { id: item.id, name: item.name, active: false };
      });
    selectedMetros && setOptionsMetros(selectedMetros);
  }, [metros, office]);

  const FormikContext = () => {
    const { setValues } = useFormikContext();

    useEffect(() => {
      if (office && !firstRender) {
        setValues({
          name: office.name,
          password: office.password,
          city: office.city.id,
          metric_area: office.metric_area,
          parking: office.parking,
          address: office.address,
          max_peoples: +office.max_peoples,
          description: office.description,
          price_category: office.price_category?.id,
        });

        setFerstRender(true);
      }
    }, [office, setValues]);

    return null;
  };

  useEffect(() => {
    activePhoto && setShowModal(true);
  }, [activePhoto]);

  useEffect(() => {
    if (office) {
      setCityId(office.city.id);
      setStatus(office.status);
      setStep(office.occupation_step);
    }
  }, [office]);

  useEffect(() => {
    const activeArr = optionsMetros
      .reduce((acum: string[], item) => {
        if (item.active) {
          acum.push(item.name);
        }
        return acum;
      }, [])
      .join(", ");
    setActiveMetros(activeArr);
  }, [optionsMetros]);

  useEffect(() => {
    getSelectParams("cities").then((res) =>
      setCities(res.data.map((item) => ({ value: item.id, name: item.name })))
    );

    officeId && getOffice(officeId).then((res) => setOffice(res.data));
  }, [officeId]);

  useEffect(() => {
    const cityName = cities.find((item) => item.value === cityId);
    cityName &&
      getPriceCategories(`?is_group=false&city=${cityName.name}`).then((res) =>
        setPriceCategories(
          res.data.map((item) => ({
            value: item.id,
            name: item.name,
            payment_system: item.payment_system.id,
          }))
        )
      );
  }, [cityId]);

  useEffect(() => {
    if (cityId) {
      const queryString = cityId ? `?city=${cityId}` : "";
      getSelectParams("metros", queryString).then((res) => setMetros(res.data));
    }
  }, [cityId]);

  const verification = () => {
    const err = {} as { [key: string]: boolean };
    const addNewValue = (name: string, value: boolean) => (err[name] = value);

    !cityId && addNewValue("city", true);
    !photoRoad.length && addNewValue("photoRoad", true);
    !photoCabinet.length && addNewValue("photo", true);
    !timesSelect.length && addNewValue("schedule", true);
    !optionsMetros.filter((item) => item.active).length &&
      addNewValue("metro", true);

    setErrors({ ...errors, ...err });
  };

  const sendOffice = async (values: typeof initial) => {
    const metorsValidation = optionsMetros.filter((item) => item.active).length;
    if (
      !timesSelect.length ||
      !photoCabinet.length ||
      !photoRoad.length ||
      !cityId ||
      !metorsValidation
    )
      return;
    const paymentSystemId = priceCategories.find(
      (item) => item.value === +values.price_category
    )?.payment_system;

    const giveZero = (time: number) => (time < 10 ? `0${time}` : time);
    const formatTime = (time: number) =>
      Number.isInteger(time)
        ? `${giveZero(time)}:00`
        : `${giveZero(Math.floor(time))}:30`;
    const initialTimes = {
      "1": "24:00-24:00",
      "2": "24:00-24:00",
      "3": "24:00-24:00",
      "4": "24:00-24:00",
      "5": "24:00-24:00",
      "6": "24:00-24:00",
      "7": "24:00-24:00",
    };

    const metroBody = optionsMetros.reduce((acum: Array<number>, item) => {
      if (item.active) {
        acum.push(item.id);
      }
      return acum;
    }, []);

    const schedule = timesSelect.reduce((acum, item) => {
      return {
        ...acum,
        [`${item.day}`]: `${formatTime(item.timeBefore)}-${formatTime(
          item.timeAfter
        )}`,
      };
    }, initialTimes);

    await Promise.all(
      photoDelete.map((item) =>
        deletePhotoOnCabinet(item.id, item.type).catch((e) => show(e))
      )
    );

    if (paymentSystemId) {
      const body = {
        ...values,
        status,
        payment_system: paymentSystemId,
        schedule,
        price_category: +values.price_category,
        city: +values.city,
        metro: metroBody,
        occupation_step: step,
        user: userId,
        metric_area: +values.metric_area,
      };
      const sendPhoto = (id: number) =>
        Promise.all([
          ...photoCabinet.map((item) => {
            if (item.file) {
              const formData = new FormData();
              formData.append("photo", item.file);
              formData.append("user", `${userId}`);
              formData.append("office", `${id}`);
              return addPhotoForCabinet(formData, "photosoffice");
            }
          }),
          ...photoRoad.map((item) => {
            if (item.file) {
              const formData = new FormData();
              formData.append("photo", item.file);
              formData.append("user", `${userId}`);
              formData.append("office", `${id}`);
              return addPhotoForCabinet(formData, "photosgetoffice");
            }
          }),
        ]);
      setLoading(true);
      office
        ? changeOffice({ ...body, id: office.id }, office.id)
            .then(() => sendPhoto(office.id))
            .then(() => {
              history.goBack();
              showAlert("success", "Информация о кабинете успешно обновлена");
            })
            .catch((e) => show(e))
            .finally(() => setLoading(false))
        : officeCreate(body)
            .then((res) => sendPhoto(res.data.id))
            .then(() => {
              history.goBack();
              showAlert("success", "Новый кабинет успешно создан");
            })
            .catch((e) => show(e))
            .finally(() => setLoading(false));
    }
  };

  const selectMetro = (id: number) =>
    setOptionsMetros(
      optionsMetros.map((item) => {
        if (item.id === id) {
          return { ...item, active: !item.active };
        }
        return item;
      })
    );

  const PhotoModalComponent = () => {
    if (activePhoto && activePhoto.url && typeof activePhoto.url === "string") {
      return (
        <ModalPhoto>
          <img src={activePhoto.url} alt="Фото" />
        </ModalPhoto>
      );
    }
    return null;
  };

  const schema = Yup.object({
    name: REQUIRED_STRING,
    password: REQUIRED_STRING,
    metric_area: REQUIRED_STRING,
    parking: REQUIRED_STRING,
    address: REQUIRED_STRING,
    max_peoples: RQUIRED_NUMBER.min(1, "Кол-во человек не может быть меньше 1"),
    description: REQUIRED_STRING.max(
      10000,
      "Максимальное число символов 10000"
    ),

    // Добавлены в схему, чтобы отработала getCorrectLabel
    // Эти поля валидируются в verification
    city: REQUIRED_STRING,
    price_category: REQUIRED_STRING,
  });

  return (
    <AddCabinetWrapper
      justify="flex-start"
      align="flex-start"
      padding="40px 200px 100px 200px"
      direction="column"
    >
      <ErrorHandler active={active} hidden={hidden} text={text} />
      <Typography
        weight="700"
        size="24px"
        margin="0 0 35px 0"
        lineHeight="21px"
      >
        {isEditing ? "Редактировать кабинет" : "Создать кабинет"}
      </Typography>
      <Formik
        initialValues={initial}
        onSubmit={(values) => sendOffice(values)}
        validationSchema={schema}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Grid justify="normal" columns="45% 45%" gap="0 10%">
              {inputs.map((item) =>
                item.name === "city" ? (
                  <Flex key={item.id} align="flex-start" direction="column">
                    <Label>
                      {getCorrectFieldLabel("Город", schema, "city")}
                    </Label>
                    <Select
                      onChange={(value: number | string | boolean) => {
                        setFieldValue("city", `${value}`);
                        setCityId(value as number);
                      }}
                      value={values.city}
                      options={cities}
                    />
                    {errors.city && (
                      <Typography color={COLORS.red} margin="5px 0" size="12px">
                        Обязательное поле
                      </Typography>
                    )}
                  </Flex>
                ) : item.name === "metro" ? (
                  <Flex key={item.id} align="flex-start" direction="column">
                    <Label>Метро*</Label>
                    <Select
                      onChange={() => {}}
                      value=""
                      options={[]}
                      many
                      optionsMany={optionsMetros}
                      manySelected={activeMetros}
                      onChangeAll={(id: number) => selectMetro(id)}
                    />
                    {errors.metro && (
                      <Typography color={COLORS.red} margin="5px 0" size="12px">
                        Обязательное поле
                      </Typography>
                    )}
                  </Flex>
                ) : (
                  <Field
                    key={item.id}
                    name={item.name}
                    label={item.label}
                    direction="column"
                    component={item.components}
                    margin="0 0 20px 0"
                    placeholder={
                      item.name === "parking" ? "Наличие парковки" : item.label
                    }
                    type={item.type}
                    schema={schema}
                  />
                )
              )}
            </Grid>
            <Field
              name="description"
              height="100px"
              label="Комментарий"
              placeholder="Опишите кабинет подробнее"
              component={FormikTextArea}
              schema={schema}
            />
            <Flex justify="flex-start" margin="30px 0 0 0">
              <Typography
                margin="0 40px 0 0"
                weight="600"
                lineHeight="17px"
                fontFamily="Montserrat"
              >
                Статус
              </Typography>
              <Flex>
                <Tab onClick={() => setStatus(true)} active={status}>
                  <Typography
                    weight={status ? "600" : "400"}
                    color={status ? COLORS.white : COLORS.black}
                    lineHeight="17px"
                    fontFamily="Montserrat"
                  >
                    Доступен для бронирования
                  </Typography>
                </Tab>
                <Tab onClick={() => setStatus(false)} isNext active={!status}>
                  <Typography
                    weight={!status ? "600" : "400"}
                    color={!status ? COLORS.white : COLORS.black}
                    lineHeight="17px"
                    fontFamily="Montserrat"
                  >
                    Недоступен для бронирования
                  </Typography>
                </Tab>
              </Flex>
            </Flex>
            <Grid
              margin="30px 0 0 0"
              justify="normal"
              columns="45% 45%"
              gap="0 10%"
            >
              <Field
                defaultValue="Выберете"
                label="Ценовая категория"
                name="price_category"
                options={priceCategories}
                component={FormikSelect}
                schema={schema}
              />
              <Flex direction="column" align="flex-start">
                <Label>Шаг брони</Label>
                <Select
                  onChange={(value: number | string | boolean) => {
                    setStep(+value as 1 | 2);
                  }}
                  defaultValue="Выберете"
                  value={step}
                  options={[
                    { value: 1, name: "Час" },
                    { value: 2, name: "Полчаса" },
                  ]}
                />
              </Flex>
            </Grid>
            <FotoInputs
              photoCabinet={photoCabinet}
              photoRoad={photoRoad}
              validationRoad={errors.photoRoad}
              validationCabinet={errors.photo}
              office={office}
              setPhotoCabinet={setPhotoCabinet}
              setPhotoRoad={setPhotoRoad}
              setPhotoDelete={setPhotoDelete}
              photoDelete={photoDelete}
              setActivePhoto={setActivePhoto}
              isRequired={true}
            />
            <DaySelects
              setTimesSelect={setTimesSelect}
              timesSelect={timesSelect}
              validation={errors.schedule}
              step={step}
              office={office}
            />
            <Flex margin="65px 0 0 0" justify="space-between">
              <Button
                type="button"
                theme="beige"
                width="155px"
                height="40px"
                radius="21px"
                weight="600"
                lineHeight="17px"
                onClick={() => history.goBack()}
              >
                Отменить
              </Button>
              <Button
                theme={office ? "green" : "grey"}
                width="155px"
                height="40px"
                radius="21px"
                weight="600"
                lineHeight="17px"
                type="submit"
                onClick={verification}
              >
                {loading ? <LoaderIcon /> : office ? "Сохранить" : "Создать"}
              </Button>
            </Flex>
            <FormikContext />
          </Form>
        )}
      </Formik>
      {showModal && (
        <Modal
          component={PhotoModalComponent}
          haveCloseBtn
          closeModal={() => {
            setActivePhoto(null);
            setShowModal(false);
          }}
        />
      )}
    </AddCabinetWrapper>
  );
};

export default AddCabinetPage;
