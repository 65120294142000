import styled from "styled-components";
import { Flex } from "../../../../components/common/Styled/Flex";
import { COLORS } from "../../../../utils/constants/colors";

const Container = styled(Flex)`
  max-width: 840px;
  margin: 0 auto;
  ${Flex} {
    width: 100%;
  }
  min-height: calc(100% - 195px);

  @media (max-width: 880px) {
    padding: 0 20px;
  }
`;

const Wrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
  width: 100%;
  flex: 1;
  background: ${COLORS.cream};
`;

const Banner = styled.img`
  width: 100%;
  height: 130px;
  object-fit: cover;
  margin-bottom: 65px;
`;

const Handshake = styled.img`
  margin: 0 55px;
`;

const Line = styled.div`
  flex: 1;
  height: 1px;
  background: ${COLORS.lightBlack};
`;

export { Container, Banner, Wrapper, Handshake, Line };
