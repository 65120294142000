import { Typography } from "../../../../../components/common/Styled/Typography";

export const Article5 = () => (
  <Typography fontFamily="Montserrat" size="16px" align="justify">
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="center">
      Статья 5. Права и обязанности Сторон
    </Typography>
    5.1. Обязательства Арендодателя по предоставлению помещения и оборудования
    согласно заказам на аренду Арендатора являются встречными по отношению к
    обязательствам Арендатора по соблюдению правил пользования помещением,
    указанных в приложении № 1 к настоящему договору, и оплате услуг. При
    невыполнении Арендатором или посетителем сайта данных обязательств
    Арендодатель может приостановить или отказаться от оказания услуг в
    соответствующей части на основании правомочий, предусмотренных гражданским
    законодательством Российской Федерации.
    <br />
    5.2. Арендатор обязуется:
    <br />
    5.2.1. До размещения заказа на аренду помещения в обязательном порядке
    предоставить Арендодателю необходимый и достаточный набор данных для
    идентификации физического лица для исполнения договора, включающий: фамилию,
    имя, отчество (при наличии), дату рождения, место рождения, номер паспорта,
    сведения о том, кем и когда был выдан паспорт, адрес регистрации, сведения о
    высшем психологическом, педагогическом или медицинском образовании. Сведения
    могут быть предоставлены как через направление фото паспорта и селфи с
    первой страницей паспорта на адрес электронной почты, так и при помощи
    видеозвонка или сообщения по предварительной договорённости путём текстового
    обращения через мессенджер, представленный в разделе «Контакты» на Сайте.
    <br />
    5.2.2. Принимать и оплачивать стоимость аренды в порядке, согласованном в
    настоящем договоре.
    <br />
    5.2.3. Использовать сайт Арендатора только в соответствии с действующим
    законодательством Российской Федерации по прямому назначению, а также
    самостоятельно знакомиться со всеми условиями сдачи помещения в аренду.
    <br />
    5.2.4. В случае отказа от настоящего договора самостоятельно отменить все
    будущие заказы (бронирования) и уведомить об этом Арендодателя по контактным
    данным, указанным на сайте Арендодателя.
    <br />
    5.2.5. Соблюдать правила пользования помещением, указанные в Приложении № 1
    к настоящему договору.
    <br />
    5.3. Арендатору запрещено сдавать арендованное помещение в субаренду,
    передавать пароли от входной двери в помещение, передавать любые данные
    своего аккаунта (профиля) третьим лицам.
    <br />
    5.4. Арендатор имеет право получать информацию о порядке, условиях,
    стоимости сдачи помещения в аренду.
    <br />
    5.5. Арендодатель обязуется: <br />
    5.5.1. Сдать помещение Арендатору в аренду надлежащим образом и в сроки,
    согласно условиям настоящего договора.
    <br />
    5.5.2. Поддерживать сайт в рабочем состоянии, обеспечивать Арендатору
    бесперебойный доступ к сервисам сайта, устранять любые неполадки в работе
    сайта в максимально короткие сроки.
    <br />
    5.6. Арендодатель имеет право:
    <br />
    5.6.1. Не передавать помещение в аренду Арендатору до момента исполнения
    Арендатором обязанности по оплате стоимости аренды помещения в порядке,
    предусмотренном в настоящем договоре.
    <br />
    5.6.2. Отказать в передаче помещения в аренду Арендатору в случае
    неисполнения последним обязанностей, предусмотренных настоящим договора
    и/или правилами. Возврат денежных средств, внесенных в качестве предоплаты,
    в случае, указанном в настоящем пункте, производится в порядке,
    предусмотренном настоящим договором и правилами.
    <br />
    5.6.3. Отказаться от настоящего Договора в соответствии с действующим
    законодательством Российской Федерации.
    <br />
    5.6.4. Отказать Субарендатору в передаче Помещения в случае отсутствия у
    Арендодателя такой возможности.
    <br />
    5.6.5. Не предоставлять Помещение при отсутствии возможности его
    предоставления, которая определяется в том числе соблюдением Субарендатором
    Правил пользования Помещением. Несоблюдение Правил может привести к
    невозможности исполнения обязательств Арендодателя по предоставлению
    Помещений.
    <br />
    5.6.6. Отказать на основании жалобы Субарендатора, фото- или видеофиксации
    камерами видеонаблюдения, сообщений обслуживающего персонала о нарушении
    Арендатором правил пользования помещением.
    <br />
    5.6.7. В случаях, предусмотренных приложениями к договору и на основании
    более 1 жалобы от различных Арендаторов на одного Арендатора о нарушении им
    правил пользования помещением.
    <br />
    5.6.8. В любой момент прекратить доступ посетителю сайта и/или Арендатору к
    сайту.
    <br />
    5.7. Соглашаясь с условиями настоящего договора и совершая акцепт настоящей
    Оферты, арендатор дает свое согласие на обработку Арендодателем его
    персональных данных в соответствии с ФЗ «О персональных данных» № 152-ФЗ от
    27.07.2006 года. Арендодатель обязуется обрабатывать персональные данные
    Арендатора строго в соответствии с действующим законодательством и для целей
    исполнения обязательств по настоящему договору. Арендодатель имеет право
    передавать персональные данные Арендатора только с его письменного согласия
    или в случаях, предусмотренных действующим законодательством Российской
    Федерации.
    <br />
    <br />
  </Typography>
);
