import { Formik, Field } from "formik";
import React, { useEffect, useState } from "react";
import Input from "../../../../components/common/FormElements/Input";
import FormikInput from "../../../../components/common/FormikElements/Input";
import { Label } from "../../../../components/common/FormikElements/Input/style";
import { Button } from "../../../../components/common/Styled/Button";
import { Flex } from "../../../../components/common/Styled/Flex";
import { Grid } from "../../../../components/common/Styled/Grid";
import { Typography } from "../../../../components/common/Styled/Typography";
import {
  ChangeType,
  SetStateType,
  VoidFuncType,
} from "../../../../types/common";
import { IBalance } from "../../../../types/interfaces/balance";
import { IPriceCategory } from "../../../../types/interfaces/priceCategories";
import { IUser } from "../../../../types/interfaces/user";
import {
  changeBalance,
  changePassword,
  createUser,
  getBalanceForUser,
  getPriceCategories,
  updateUser,
} from "../../../../utils/api/routes";
import { COLORS } from "../../../../utils/constants/colors";
import { PopupWrapper, StyledForm, Tab } from "./style";
import * as Yup from "yup";
import {
  REQUIRED_STRING,
  PHONE,
  EMAIL,
  validationMessages,
  INITIALS,
  linkValidation,
  CONFIRM,
  PASSWORD,
} from "../../../../utils/formik/validation";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";
import ErrorHandler from "../../../../components/Error";
import Modal from "../../../../components/Modal";
import CloseModalPopup from "../ClosedModalPopup";
import { showAlert } from "../../../../utils/showAlert";

interface IProps {
  closeModal: VoidFuncType;
  version: boolean;
  user?: IUser | null;
  updateUserComponent: (search?: string, page?: number) => void;
  showWarraningModal: boolean;
  setShowWarrningModal: SetStateType<boolean>;
  closeParentModal: VoidFuncType;
}

const AddPsyhoPopup = ({
  closeModal,
  version,
  user,
  updateUserComponent,
  showWarraningModal,
  setShowWarrningModal,
  closeParentModal,
}: IProps) => {
  const [negativeBalance, setNegativeBalance] = useState(
    !version && user ? user.negative_balance : false
  );
  const [priceCategories, setPriceCategories] = useState<Array<IPriceCategory>>(
    []
  );
  const [balance, setBalance] = useState<Array<IBalance>>([]);
  const [priceCategoryData, setPriceCategoryData] = useState<{
    [key: string]: string;
  }>({});
  const { show, active, hidden, text } = useErrorHandler();

  useEffect(() => {
    getPriceCategories().then((res) => setPriceCategories(res.data));
    !version &&
      user &&
      getBalanceForUser(user.id).then((res) => setBalance(res.data));
  }, []);

  useEffect(() => {
    const intial = priceCategories.reduce((acum, item) => {
      return { ...acum, [`price_category_${item.id}`]: 0 };
    }, {});
    const formData = balance.reduce((acum, item) => {
      return {
        ...acum,
        [`price_category_${item.price_category?.id}`]: item.count,
      };
    }, intial);
    setPriceCategoryData(formData);
  }, [priceCategories, balance]);

  const initial = {
    name:
      !version && user
        ? `${user.last_name} ${user.first_name} ${user.middle_name}`
        : "",
    phone: !version && user ? user.phone : "",
    email: !version && user ? user.email : "",
    vk: !version && user ? user.vk : "",
    instagram: !version && user ? user.instagram : "",
    telegram: !version && user ? user.telegram : "",
    password: "",
    ...(version && { passwordConfirm: "" }),
  };

  const createBody = (values: typeof initial) => {
    const name = values.name.split(" ");
    return {
      ...values,
      email: values.email.trim().toLowerCase(),
      last_name: name[0].trim(),
      first_name: name[1].trim(),
      middle_name: name.slice(2).join(" ")?.trim() ?? "",
      negative_balance: negativeBalance,
      role: "psychologist",
    };
  };

  const changeUser = (values: typeof initial) => {
    if (!user) return;
    updateUser(createBody(values), `${user.id}`).then((res) => {
      changeBalance({
        user: user.id,
        balances: Object.keys(priceCategoryData).map((item) => ({
          price_category: +item.split("_")[2],
          count: +priceCategoryData[item],
        })),
      })
        .then(() => {
          closeParentModal();
          updateUserComponent("", 1);
          showAlert("success", "Информация о психологе успешно обновлена");
        })
        .catch((e) => {
          show(e);
        });
    });
    if (values.password) {
      changePassword(user.id, { password: values.password }).catch((e) =>
        show(e)
      );
    }
  };

  const addUser = (values: typeof initial) => {
    createUser(createBody(values))
      .then((res) => {
        changeBalance({
          user: res.data.id,
          balances: Object.keys(priceCategoryData).map((item) => ({
            price_category: +item.split("_")[2],
            count: +priceCategoryData[item],
          })),
        })
          .then(() => {
            closeParentModal();
            updateUserComponent("", 1);
            showAlert("success", "Психолог успешно добавлен");
          })
          .catch((e) => {
            show(e);
          });
      })
      .catch((e) => show(e));
  };

  const schema = Yup.object({
    name: INITIALS,
    phone: PHONE,
    email: EMAIL,
    password: version ? PASSWORD : Yup.string(),
    vk: linkValidation("vk.com"),
    instagram: linkValidation("www.instagram.com"),
    telegram: linkValidation("t.me"),
    ...(version && { passwordConfirm: CONFIRM }),
  });
  const changePriceCategory = (e: ChangeType, id: number) => {
    !Number.isNaN(+e.target.value) &&
      setPriceCategoryData({
        ...priceCategoryData,
        [`price_category_${id}`]: e.target.value,
      });
  };

  const blurPriceCategory = (e: ChangeType, id: number) => {
    if (!priceCategoryData[`price_category_${id}`]) {
      const oldValue = balance.find((item) => item.price_category?.id === id);
      setPriceCategoryData({
        ...priceCategoryData,
        [`price_category_${id}`]: oldValue?.count ? `${oldValue?.count}` : "0",
      });
    }
  };

  return (
    <PopupWrapper direction="column">
      <ErrorHandler active={active} hidden={hidden} text={text} />
      <Flex
        width="100%"
        padding="10px 0"
        margin="0 0 25px 0"
        border="1px solid #DECEB7"
      >
        <Typography weight="700" size="18px" lineHeight="21px">
          {version ? "Создание психолога" : "Редактировать психолога"}
        </Typography>
      </Flex>
      <Formik
        initialValues={initial}
        onSubmit={(values) => (version ? addUser(values) : changeUser(values))}
        validationSchema={schema}
      >
        <StyledForm>
          <Field
            disabled={!version}
            direction="column"
            name="name"
            component={FormikInput}
            placeholder="ФИО"
            label="ФИО"
            schema={schema}
          />
          <Field
            direction="column"
            name="phone"
            component={FormikInput}
            placeholder="Номер телефона"
            label="Номер телефона"
            schema={schema}
          />
          <Field
            direction="column"
            name="email"
            component={FormikInput}
            placeholder="Email"
            label="Email"
            schema={schema}
          />
          {/* <Grid margin="0 0 15px 0" columns="repeat(2, 45%)" gap="0 10%"> */}
          <Field
            // description={`В формате:\nhttps://vk.com/id_пользователя`}
            direction="column"
            name="vk"
            component={FormikInput}
            placeholder="https://vk.com/id_пользователя"
            label="Вконтакте"
            schema={schema}
          />
          <Field
            // description={`В формате:\nhttps://www.instagram.com/ник_пользователя`}
            direction="column"
            name="instagram"
            component={FormikInput}
            placeholder="https://www.instagram.com/ник_пользователя"
            label="Инстаграм"
            schema={schema}
          />
          <Field
            // description={`В формате:\nhttps://t.me/ник_пользователя`}
            direction="column"
            name="telegram"
            component={FormikInput}
            placeholder="https://t.me/ник_пользователя"
            label="Телеграм"
            schema={schema}
          />
          {/* </Grid> */}
          <Field
            direction="column"
            name="password"
            component={FormikInput}
            placeholder="Пароль"
            label="Пароль"
            schema={schema}
          />
          {version && (
            <Field
              direction="column"
              name="passwordConfirm"
              component={FormikInput}
              placeholder="Подтвердите пароль"
              label="Подтвердите пароль"
              schema={schema}
            />
          )}
          <Flex width="100%" justify="space-between">
            <Typography weight="600" lineHeight="17px" fontFamily="Montserrat">
              Отрицательный баланс
            </Typography>
            <Flex>
              <Tab
                onClick={() => setNegativeBalance(true)}
                active={negativeBalance}
              >
                <Typography
                  weight={negativeBalance ? "600" : "400"}
                  color={negativeBalance ? COLORS.white : COLORS.black}
                  lineHeight="17px"
                  fontFamily="Montserrat"
                >
                  Разрешен
                </Typography>
              </Tab>
              <Tab
                onClick={() => setNegativeBalance(false)}
                isNext
                active={!negativeBalance}
              >
                <Typography
                  weight={!negativeBalance ? "600" : "400"}
                  color={!negativeBalance ? COLORS.white : COLORS.black}
                  lineHeight="17px"
                  fontFamily="Montserrat"
                >
                  Не разрешен
                </Typography>
              </Tab>
            </Flex>
          </Flex>
          <Typography weight="700" size="18px" lineHeight="21px">
            Баланс (часы)
          </Typography>
          {priceCategories.map((item) => (
            <Flex width="100%" key={item.id}>
              <Label width="200%">
                {item.name} {item.is_group && "(групповая)"}
              </Label>
              <Input
                type="number"
                value={priceCategoryData[`price_category_${item.id}`]}
                onFocus={() =>
                  setPriceCategoryData({
                    ...priceCategoryData,
                    [`price_category_${item.id}`]: "",
                  })
                }
                onBlur={(e: ChangeType) => blurPriceCategory(e, item.id)}
                onChange={(e: ChangeType) => changePriceCategory(e, item.id)}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              />
            </Flex>
          ))}
          <Flex margin="30px 0 0 0" justify="space-between">
            <Button
              type="button"
              theme="beige"
              width="155px"
              height="40px"
              radius="21px"
              weight="600"
              lineHeight="17px"
              onClick={closeModal}
            >
              Отменить
            </Button>
            <Button
              type="submit"
              theme={version ? "grey" : "green"}
              width="155px"
              height="40px"
              radius="21px"
              weight="600"
              lineHeight="17px"
            >
              {version ? "Создать" : "Сохранить"}
            </Button>
          </Flex>
        </StyledForm>
      </Formik>
      {showWarraningModal && (
        <Modal
          component={CloseModalPopup}
          haveCloseBtn
          closeModal={() => setShowWarrningModal(false)}
          parameters={{
            closeParentModal,
          }}
        />
      )}
    </PopupWrapper>
  );
};

export default AddPsyhoPopup;
