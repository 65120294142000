import { Typography } from "../../../../../components/common/Styled/Typography";

export const AgreementIntro = () => (
  <Typography fontFamily="Montserrat" size="16px" align="justify">
    <br />
    <br />
    <span style={{ fontWeight: 600 }}>г. Санкт-Петербург</span>
    <br />
    <br />
    Индивидуальный предприниматель Петров Максим Владимирович (ИНН 781140733151,
    ОГРНИП 321784700275297), зарегистрированный по адресу: 192076, Россия, г.
    Санкт-Петербург, пр. Шлиссельбургский, д. 12, корп. 1, кв. 203, именуемый в
    дальнейшем Арендодатель, с одной стороны, и клиент, согласившийся с
    условиями данного договора при регистрации в личном кабинете перед первым
    бронированием на сайте https://psy-office.pro, именуемый/ая в дальнейшем
    Арендатор, с другой стороны, заключили настоящий договор о нижеследующем:
    <br />
    <br />
  </Typography>
);
