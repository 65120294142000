import { Typography } from "../../../../../components/common/Styled/Typography";

export const Article4 = () => (
  <Typography fontFamily="Montserrat" size="16px" align="justify">
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="center">
      Статья 4. Стоимость услуг и порядок расчетов
    </Typography>
    4.1. Стоимость одного часа аренды помещения определяется на основе цен,
    установленных у Арендодателя, и указывается на сайте в разделе «Покупки»,
    расположенном по адресу: https://psy-office.pro/purchases. Арендодатель
    вправе в одностороннем порядке в любой момент изменить стоимость аренды
    помещения. Изменения вступают в силу с момента их размещения на сайте в
    разделе «Покупки», расположенном по адресу:
    https://psy-office.pro/purchases. Изменение стоимости аренды не влияет на
    стоимость часов аренды, включенных в ранее приобретенные абонементы. <br />
    4.2. Расчёты между сторонами производятся в российских рублях в порядке,
    предусмотренном правилами, являющимися неотъемлемой частью настоящего
    договора.
    <br />
    4.3. В случае отказа Арендодателя от сдачи помещения в аренду, по основания,
    указанным в настоящем договора и правилах, Арендодатель в течение 10
    (десяти) рабочих дней возвращает Арендатору уплаченные в качестве предоплаты
    денежные средства по реквизитам, указанным Арендатором при оплате, либо по
    иным реквизитам с приложением письменного заявления на имя Арендодателя. В
    этот срок не включается время, необходимое для обработки платежа банками и
    иными использованными для возврата средств платежными системами.
    <br />
    <br />
  </Typography>
);
