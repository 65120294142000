import moment from "moment";
import React, { useEffect, useState } from "react";
import { IAdminModalProps } from "..";
import {
  EmailIcon,
  InstIcon,
  PhoneIcon,
  TelegramIcon,
  UserIcon,
  VkIcon,
} from "../../../../assets/icons";
import { Button } from "../../../../components/common/Styled/Button";
import { Flex } from "../../../../components/common/Styled/Flex";
import { Grid } from "../../../../components/common/Styled/Grid";
import {
  Table,
  CeilHeader,
  Ceil,
} from "../../../../components/common/Styled/Table";
import { Typography } from "../../../../components/common/Styled/Typography";
import { VoidFuncType } from "../../../../types/common";
import { IBalance } from "../../../../types/interfaces/balance";
import { IHistory } from "../../../../types/interfaces/history";
import { IPriceCategory } from "../../../../types/interfaces/priceCategories";
import { IUser } from "../../../../types/interfaces/user";
import {
  banUser,
  deleteUser,
  getBalanceForUser,
  getHistoryForUser,
  getPriceCategories,
  unbanUser,
} from "../../../../utils/api/routes";
import { COLORS } from "../../../../utils/constants/colors";
import { formatPhoneNumber } from "../../../../utils/formik/formatPhoneNumber";
import { UserInfoWrapper, Status } from "./style";

interface IProps extends IAdminModalProps {
  updateUserComponent: (search?: string, page?: number) => void;
  user: IUser;
}

const UserInfo = ({
  setShowModal,
  setVersion,
  page,
  user,
  updateUserComponent,
}: IProps) => {
  const [balance, setBalance] = useState<Array<IBalance>>([]);
  const [priceCategories, setPriceCategories] = useState<Array<IPriceCategory>>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [history, setHistory] = useState<Array<IHistory>>([]);
  const [countHistory, setCountHistory] = useState(0);
  useEffect(() => {
    getBalanceForUser(user.id).then((res) => setBalance(res.data));
    getPriceCategories().then((res) => setPriceCategories(res.data));
    getHistoryForUser(user.id, `&page_size=2&page=${currentPage}`).then(
      (res) => {
        setHistory(res.data.results);
        setCountHistory(res.data.count);
      }
    );
    setCurrentPage(1);
  }, [user]);

  const nextPage = () => setCurrentPage((prevState) => prevState + 1);

  useEffect(() => {
    currentPage > 1 &&
      getHistoryForUser(user.id, `&page_size=2&page=${currentPage}`).then(
        (res) => {
          setHistory([...history, ...res.data.results]);
          setCountHistory(res.data.count);
        }
      );
  }, [currentPage]);

  const getCountTime = (id: number) => {
    const catgoryBalance = balance.find(
      (elem) => elem.price_category?.id === id && elem.subscription == null
    );
    if (catgoryBalance) return catgoryBalance.count;
    return "0";
  };
  return (
    <UserInfoWrapper direction="column" padding="28px">
      <Flex
        padding="0 0 25px 0"
        align="flex-start"
        width="100%"
        direction="column"
        border="1px solid #DECEB7"
      >
        <Flex align="center" margin="15px 0 0 0">
          <UserIcon />
          <Typography
            margin="0 0 0 12px"
            size="18px"
            lineHeight="21px"
          >{`${user.last_name} ${user.first_name} ${user.middle_name}`}</Typography>
        </Flex>
        <Flex margin="15px 0 0 0">
          <EmailIcon />
          <Typography margin="0 0 0 12px" size="18px" lineHeight="21px">
            {user.email}
          </Typography>
        </Flex>
        <Flex margin="15px 0 0 0" width="100%" justify="space-between">
          <Flex>
            <PhoneIcon />
            <Typography margin="0 0 0 12px" size="18px" lineHeight="21px">
              {formatPhoneNumber(user.phone)}
            </Typography>
          </Flex>
          <Flex gap="10px">
            {user.instagram && (
              <a target="__blank" href={user.instagram}>
                <InstIcon />
              </a>
            )}
            {user.telegram && (
              <a target="__blank" href={user.telegram}>
                <TelegramIcon />
              </a>
            )}
            {user.vk && (
              <a target="__blank" href={user.vk}>
                <VkIcon />
              </a>
            )}
          </Flex>
        </Flex>
      </Flex>
      {page === 1 && (
        <>
          <Flex
            width="100%"
            direction="column"
            align="flex-start"
            padding="0 0 25px 0"
            margin="25px 0 0 0"
            border="1px solid #DECEB7"
          >
            <Typography weight="700" size="22px" lineHeight="26px">
              Отрицательный баланс
            </Typography>
            <Typography
              margin="6px 0 0 0"
              fontFamily="Montserrat"
              weight="500"
              size="16px"
              lineHeight="20px"
            >
              {user.negative_balance ? "Разрешено" : "Запрещено"}
            </Typography>
          </Flex>
          <Flex
            align="flex-start"
            padding="0 0 25px 0"
            margin="25px 0 0 0"
            width="100%"
            direction="column"
            border="1px solid #DECEB7"
          >
            <Typography
              margin="0 0 20px 0"
              weight="700"
              size="22px"
              lineHeight="26px"
            >
              Баланс
            </Typography>
            {priceCategories.map((item) => (
              <Grid
                columns="repeat(3, 40%)"
                gap="5px"
                key={item.id}
                margin="6px 0 0 0"
                w="100%"
              >
                <Typography weight="700" size="16px" lineHeight="19px">
                  {item.name} {item.is_group && "(групповая)"}
                </Typography>
                <Typography lineHeight="18px">{item.price}₽ / час</Typography>
                <Typography weight="700" size="16px" lineHeight="19px">
                  {getCountTime(item.id)} часов
                </Typography>
              </Grid>
            ))}
          </Flex>
          <Flex
            align="flex-start"
            width="100%"
            direction="column"
            padding="0 0 25px 0"
            margin="25px 0 0 0"
          >
            <Typography
              margin="0 0 20px 0"
              weight="700"
              size="22px"
              lineHeight="26px"
            >
              История
            </Typography>
            <Table columns="repeat(4, 25%)">
              <CeilHeader borderRadius="first">
                <Typography weight="700" lineHeight="18px">
                  Дата операции
                </Typography>
              </CeilHeader>
              <CeilHeader>
                <Typography weight="700" lineHeight="18px">
                  Автор операции
                </Typography>
              </CeilHeader>
              <CeilHeader>
                <Typography weight="700" lineHeight="18px">
                  Операция
                </Typography>
              </CeilHeader>
              <CeilHeader borderRadius="last">
                <Typography weight="700" lineHeight="18px">
                  Кол-во часов
                </Typography>
              </CeilHeader>
              {history.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Ceil
                    borderRadius="first"
                    isLastRow={index === history.length - 1}
                  >
                    <Typography lineHeight="18px">
                      {moment(item.updated).format("DD MM YYYY")}
                    </Typography>
                  </Ceil>
                  <Ceil>
                    <Typography lineHeight="18px">
                      {item.author.last_name} {item.author.first_name}
                    </Typography>
                  </Ceil>
                  <Ceil>
                    <Typography lineHeight="18px">
                      {item.amount > 0 ? "Прибавление" : "Удаление"}
                    </Typography>
                  </Ceil>
                  <Ceil
                    isLastRow={index === history.length - 1}
                    borderRadius="last"
                  >
                    <Typography lineHeight="18px">
                      {Math.abs(item.amount)}
                    </Typography>
                  </Ceil>
                </React.Fragment>
              ))}
            </Table>
            {history.length !== countHistory && (
              <Flex
                onClick={nextPage}
                cursor="pointer"
                width="100%"
                margin="5px 0 0 0"
              >
                <Typography color={COLORS.steel}>Показать ещё</Typography>
              </Flex>
            )}
          </Flex>
        </>
      )}
      {page === 2 && (
        <Flex
          margin="25px 0 0 0"
          align="flex-start"
          width="100%"
          direction="column"
        >
          <Typography
            margin="0 0 20px 0"
            weight="700"
            size="22px"
            lineHeight="26px"
          >
            Права
          </Typography>
          <Flex margin="0 0 6px 0" justify="space-between" width="80%">
            <Typography weight="700" size="16px" lineHeight="19px">
              Кабинеты
            </Typography>
            <Typography lineHeight="18px">
              {user.cabinets_all ? "Видит все" : "Видит только свои"}
            </Typography>
          </Flex>
          <Flex margin="0 0 6px 0" justify="space-between" width="80%">
            <Typography weight="700" size="16px" lineHeight="19px">
              Психологи
            </Typography>
            <Typography lineHeight="18px">
              {user.psychologist_all ? "Видит всех" : "Видит только своих"}
            </Typography>
          </Flex>
          <Flex justify="space-between" width="80%">
            <Typography weight="700" size="16px" lineHeight="19px">
              Уведомления
            </Typography>
            <Typography lineHeight="18px">
              {user.notifications_all
                ? "Может отправлять всем"
                : "Может отправлять только своим"}
            </Typography>
          </Flex>
        </Flex>
      )}
      <Flex margin="40px 0 0 0" width="100%" justify="space-between">
        <Button
          theme="darkRed"
          radius="21px"
          width="155px"
          height="40px"
          weight="600"
          lineHeight="17px"
          onClick={async () => {
            await deleteUser(user.id);
            updateUserComponent("", 1);
          }}
        >
          Удалить
        </Button>
        <Button
          theme={user.is_active ? "darkRed" : "green"}
          radius="21px"
          width="155px"
          height="40px"
          weight="600"
          lineHeight="17px"
          onClick={async () => {
            user.is_active ? await banUser(user.id) : await unbanUser(user.id);
            updateUserComponent("", 1);
          }}
        >
          {user.is_active ? "Заблокировать" : "Разблокировать"}
        </Button>
        <Button
          theme="grey"
          radius="21px"
          width="155px"
          height="40px"
          weight="600"
          lineHeight="17px"
          onClick={() => {
            setVersion(false);
            setShowModal(true);
          }}
        >
          Редактировать
        </Button>
      </Flex>
      <Status status={user.is_active}>
        <Typography
          color={COLORS.steel}
          weight="600"
          size="13px"
          lineHeight="16px"
        >
          {user.is_active ? "Активный" : "Заблокированный"}
        </Typography>
      </Status>
    </UserInfoWrapper>
  );
};
export default UserInfo;
