import { Typography } from "../../../../../components/common/Styled/Typography";

export const Article3 = () => (
  <Typography fontFamily="Montserrat" size="16px" align="justify">
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="center">
      Статья 3. Порядок оказания услуг
    </Typography>
    3.1. Арендатор размещает заказ на аренду помещения на сайте, указывая свою
    фамилию, имя, отчество, адрес электронной почты, номер телефона, дату и
    количество часов аренды, а также адрес предполагаемого помещения для аренды.
    Помещение предоставляется в субаренду на условиях почасового временного
    владения и пользования. <br />
    3.2. После размещения Арендатором заказа, указанного в п. 3.1 настоящего
    договора, и при наличии свободного времени для аренды помещения,
    Арендодатель направляет Арендатору на указанные в заказе контактные данные
    пароль для доступа в помещение. <br />
    3.3. Для доступа в помещение арендатор вводит цифровой пароль в электронном
    кодовом замке. Он может быть использован для доступа в помещение в
    промежутке от времени за 5 (пять) минуты до начала времени аренды помещения
    до 5 (пяти) минут до окончания времени аренды помещения. <br />
    3.4. Арендатор обязан покинуть помещение за 5 (пять) минуты до окончания
    срока времени аренды. <br />
    3.5. Арендодатель вправе отказаться от сдачи в аренду помещения в случае
    отсутствия у Арендодателя возможности сдать помещение в аренду и в иных
    случаях, предусмотренных действующим законодательством Российской Федерации.{" "}
    <br />
    3.6. В силу характера предлагаемых Арендодателем услуг, направленных на
    удовлетворение индивидуальных потребностей Арендатора, в рамках договора
    Арендодатель не принимает на себя обязанности по предоставлению помещения
    каждому лицу, которое к ней обратится, и может отказать в предоставлении
    помещения. Например, в случаях, когда цель аренды помещения Арендатором не
    соответствует правилам пользования помещением, его оснащению, площади,
    вместимости, отсутствие свободного помещения на выбранное время и пр.
    <br />
    3.7. Обязанность Арендодателя по передаче помещения в субаренду считается
    выполненной с момента направления пароля в порядке, предусмотренном
    настоящим договором.
    <br />
    3.8. Арендатор обязан вернуть Арендодателю имущество в том состоянии, в
    котором он его получил, с учетом нормального износа.
    <br />
    <br />
  </Typography>
);
