import { Typography } from "../../../../../components/common/Styled/Typography";

export const Article9 = () => (
  <Typography fontFamily="Montserrat" size="16px" align="justify">
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="center">
      Статья 9. Обстоятельства непреодолимой силы (форс-мажор)
    </Typography>
    9.1. Сторона, не исполнившая или ненадлежащим образом исполнившая свои
    обязательства по Договору, несет ответственность, если не докажет, что
    надлежащее исполнение обязательств оказалось невозможным вследствие
    непреодолимой силы (форс-мажор), то есть чрезвычайных и непредотвратимых
    обстоятельств при конкретных условиях конкретного периода времени, которая
    ни одна из сторон не могла ни предвидеть, ни предотвратить разумным
    способом. Наличие форс-мажорных обстоятельств должно быть подтверждено
    уполномоченным государственным органом.
    <br />
    9.2. Неуведомление или несвоевременное уведомление сторон Договора о
    наступлении форс-мажорных обстоятельств стороной, которая на них ссылается,
    лишает эту сторону права ссылаться на них в дальнейшем.
    <br />
    9.3. Если последствия, вызванные этими форс-мажорными обстоятельствами,
    будут длиться более 30 (тридцати) календарных дней, то стороны встретятся
    для обсуждения создавшейся ситуации и принятия мер по преодолению
    создавшейся ситуации, однако если в течение дополнительных 10 (десяти)
    календарных дней стороны не смогут найти выход из создавшейся ситуации, то
    любая из сторон вправе требовать расторжения договора в одностороннем
    порядке. Факты, изложенные в извещении, должны быть подтверждены
    компетентными государственными органами или организациями по месту
    наступления таких обстоятельств.
    <br />
    <br />
  </Typography>
);
