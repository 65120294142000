import React from "react";
import { useHistory } from "react-router";
import { Button } from "../../components/common/Styled/Button";
import { Flex } from "../../components/common/Styled/Flex";
import { Typography } from "../../components/common/Styled/Typography";
import useResolution from "../../hooks/useResolution";

const Rules = () => {
  const isMobile = useResolution(1000);
  const history = useHistory();
  return (
    <Flex
      padding={isMobile ? "60px 20px 10px 20px" : "60px"}
      maxHeight={isMobile ? "calc(100vh - 100px)" : "100vh"}
      overflow="auto"
      align="flex-start"
      justify="flex-start"
      direction="column"
    >
      <Typography weight="800" size="24px" lineHeight="29px">
        Правила
      </Typography>
      <Flex margin="10px 0 0 0" align="flex-start" direction="column">
        <Typography size="20px" weight="600">
          Вы можете оплатить бронирование кабинетов как единоразовым платежом,
          так и с помощью абонемента.
        </Typography>
        <Typography margin="10px 0 0 0" size="16px" weight="600">
          Правила пользования абонементами:
        </Typography>
        <Typography
          lineHeight="30px"
          margin="0 0 0 20px"
          size="16px"
          weight="500"
        >
          1. Есть несколько категорий абонементов, в которые входят определённые
          кабинеты (см. список Категории абонементов). <br />
          2. Абонемент действует в течение года с момента покупки. Вы можете
          тратить часы в любой момент действия абонемента. <br />
          3. Абонементом могут быть оплачены только следующие после покупки
          абонемента часы, им нельзя оплатить уже прошедшие брони.
        </Typography>
        <Typography margin="10px 0 0 0" size="16px" weight="600">
          Категории абонементов:
        </Typography>
        <Typography
          lineHeight="30px"
          margin="0 0 0 20px"
          size="16px"
          weight="500"
        >
          1. Абонемент «Стандарт». В данный абонемент входят кабинеты на
          станциях метро Чернышевская, Невский проспект, Сенная площадь,
          Петроградская (кроме центра на Петропавловской), пл. Александра
          Невского, Маяковская, Гостиный двор. <br />
          2. Абонемент «Петропавловская». В данный абонемент входят 3 кабинета
          психологического центра на Петропавловской 6. <br />
          3. Абонемент «Плюс». В данный абонемент входят кабинеты на станциях
          метро Адмиралтейская, Технологический Институт и Василеостровская.
          <br />
          4. Абонемент «Групповой». В данный абонемент входят кабинеты 1,3,6 на
          станции метро Сенная площадь (ул. Ефимова 1/4). Чтобы забронировать
          групповой кабинет необходимо выбрать количество посетителей от 5
          человек.
        </Typography>
        <Typography margin="10px 0 0 0" size="16px" weight="600">
          Правила бронирования:
        </Typography>
        <Typography
          lineHeight="30px"
          margin="0 0 0 20px"
          size="16px"
          weight="500"
        >
          1. Средства за бронь списываются только по окончанию бронирования.{" "}
          <br />
          2. Оплата прошедшего бронирования должна быть совершена в течение
          суток. Если оплата не поступила, доступ к бронированию ограничивается.{" "}
          <br />
          3. Бесплатная отмена бронирования дневного времени до 17:00 — за 24
          часа.
          <br />
          4. Бесплатная отмена бронирования вечернего времени с 17:00 — за 48
          часов.
          <br />
          5. В случае если вы создали бронь до 17:00 менее, чем за 24 часа или
          после 17:00 часов менее, чем за 48 часов отмена брони осуществляется
          бесплатно.
          <br />
          6. Бесплатная отмена 4 и более часов в день — за 4 суток (96 часов).
          <br />
          7. Перенос брони в течение одного дня осуществляется бесплатно. Вы
          можете бесплатно перенести свою бронь в день приема на другое время и
          в любой другой свободный кабинет.
          <br />
          8. Есть возможность встать в очередь. Для этого на уже забронированное
          время нужно нажать и забронировать. Бронь будет отображаться красным
          цветом. Если время освободится — вам придёт уведомление о том, что что
          вы можете забронировать освободившееся время. Чтобы закрепить время за
          собой, нужно подтвердить бронирование. На подтверждение отводится 3
          часа с момента освобождения (за исключением ночного времени, в этот
          период таймер не включён). Если за 3 часа вы не подтвердили бронь, то
          она либо освобождается, либо переходит к следующему за вами в очереди.
          <br />
          9. Если вы несвоевременно отменили бронь, то списание произойдёт
          только в момент начала брони. До этого изменения в балансе не будет.
          Если другой психолог забронирует на освобождённое вами время — с вас
          не будут списываться средства. Если время останется пустым — средства
          спишутся с баланса.
        </Typography>
        <Button
          theme="green"
          margin="30px 0 0 0"
          width="100%"
          height="34px"
          radius="6px"
          weight="700"
          onClick={() => history.push("/purchases")}
        >
          Вернуться к покупкам
        </Button>
      </Flex>
    </Flex>
  );
};

export default Rules;
