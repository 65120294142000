import { Typography } from "../../../../../components/common/Styled/Typography";

export const Article10 = () => (
  <Typography fontFamily="Montserrat" size="16px" align="justify">
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="center">
      Статья 10. Срок действия договора
    </Typography>
    10.1. Договор действует до полного исполнения обязательств сторонами, но в
    период действия заказа на аренду помещения. Наличие внесенного аванса при
    отсутствии неисполненного заказа не обязывает Арендодателя продлевать срок
    действия договора. В этом случае авансовый платеж может быть возвращен
    Арендатору как по инициативе Арендатора, так и Арендодателя, а также
    авансовый платеж может быть зачтён при заключении нового договора аренды.
    <br />
    10.2. Стороны имеют право отказаться от настоящего договора в одностороннем
    порядке в порядке, предусмотренном действующим законодательством Российской
    Федерации.
    <br />
    <br />
  </Typography>
);
