import { Typography } from "../../../../../components/common/Styled/Typography";

export const Article1 = () => (
  <Typography fontFamily="Montserrat" size="16px" align="justify">
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="center">
      Статья 1. Предмет договора
    </Typography>
    1.1. По настоящему договору Арендодатель обязуется передать Арендатору за
    плату во временное владение и пользование помещение на условиях и в порядке,
    предусмотренном в настоящем договоре, а Арендатор обязуется оплатить
    пользование помещением, в порядке и на условиях, предусмотренных настоящим
    договором. <br />
    1.2. Условия настоящего договора могут быть в любой момент изменены
    Арендодателем в одностороннем порядке, изменения условий настоящего договора
    вступают в силу с момента размещения внесенных изменений на сайте. <br />
    1.3. Настоящий договор является договором присоединения, заключение
    настоящего договора происходит в порядке ст. 428 ГК РФ. Акцептом настоящей
    оферты признаются следующая совокупность действия Арендатора: размещение
    заказа на сайте и введение пароля (электронного пароля, электронного ключа)
    для получения доступа к помещению. <br />
    1.4. Применимым правом по настоящему договору является право Российской
    Федерации. Отношения сторон настоящего Договора регулируются
    законодательством Российской федерации, настоящим Договором, обычаями
    делового оборота.
    <br />
    <br />
  </Typography>
);
