import { Form, Formik, Field, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBackIcon } from '../../../../assets/icons';
import Input from '../../../../components/common/FormElements/Input';
import Select from '../../../../components/common/FormElements/Select';
import DateFormikFilter from '../../../../components/common/FormikElements/DateFilter';
import FormikInput from '../../../../components/common/FormikElements/Input';
import { Label, SwitchingLabel } from '../../../../components/common/FormikElements/Input/style';
import FormikSelect from '../../../../components/common/FormikElements/Select';
import { Button } from '../../../../components/common/Styled/Button';
import { Flex } from '../../../../components/common/Styled/Flex';
import { Typography } from '../../../../components/common/Styled/Typography';
import ErrorHandler from '../../../../components/Error';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { useErrorHandler } from '../../../../hooks/useErrorHandler';
import { usePagination } from '../../../../hooks/usePagination';
import { bookingCancel } from '../../../../store/reducers/bookingReducer';
import { RootState } from '../../../../store/reducers/rootReducer';
import { ChangeType, OptionType, SetStateType, VoidFuncType } from '../../../../types/common';
import { IBooking } from '../../../../types/interfaces/booking';
import { IOffice } from '../../../../types/interfaces/office';
import { IUser } from '../../../../types/interfaces/user';
import { createBooking, deleteBooking, getAllOffices, getBookings, getSelectParams, getUsers } from '../../../../utils/api/routes';
import { showAlert } from '../../../../utils/showAlert';
import { DropdownSearch, List, ListItem, PopupWrapper, ArrowBackWrapper } from './style';
import { momentWeekDayToWeekDay, numberToTime, searchAllWeekDaysOnMonth, timeToNumber, weekDayToMomentWeekDay } from './utils';

interface IProps {
    booking?: IBooking | null
    setOfficeGlobal: SetStateType<IOffice | null>
    localTime: { time: string, date: string }
    close: VoidFuncType
    update: VoidFuncType
    setBooking: SetStateType<IBooking | null>
    upadateReservations: VoidFuncType
}

const BookingsPopup = ({ booking, setOfficeGlobal, localTime, close, update, setBooking, upadateReservations }: IProps) => {
    const { profile } = useSelector((state: RootState) => state.user)

    const [cities, setCities] = useState<Array<OptionType>>([])
    const [bookings] = useState<Array<IBooking>>([])
    const [offices, setOffices] = useState<Array<IOffice>>([])
    const [office, setOffice] = useState<IOffice | null>(booking ? booking.office : null)
    const [currentPage, setCurrentPage] = useState(2)
    const [totalCount, setTotalCount] = useState(0)
    const [city, setCity] = useState(booking ? booking.office.city.id : '')
    const [search, setSearch] = useState('')
    const [times, setTimes] = useState({ timeBefore: '', timeAfter: '' })
    const [users, setUsers] = useState<Array<IUser>>([])
    const [user, setUser] = useState<IUser | null>(booking ? booking.user : null)
    const [showList, setShowList] = useState(false)
    const [validation, setValidation] = useState(false)
    const [validationMyField, setValidationMyField] = useState(false)
    const [countUser, setCountUser] = useState(0)
    const [date, setDate] = useState(booking ? moment(booking.date).format('DD.MM.YYYY') : '')
    const [currentMonth, setCurrentMonth] = useState(moment().format('DD.MM.YYYY'))

    const [isGuestRole, setIsGuestRole] = useState(!user ? true : false)
    const [guestValue, setGuestValue] = useState(booking?.unknown_user || '')

    const ref = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const { fetching, currentPage: currentUserPage, finalyCallback, successCallback, resetCallback, scroll } = usePagination(countUser === users.length)
    const { show, active, hidden, text } = useErrorHandler()
    useClickOutside(ref, () => setShowList(false))

    useEffect(() => {
        getSelectParams('cities').then(res => setCities(res.data.map(item => ({ value: item.id, name: item.name }))))
    }, [])

    useEffect(() => {
        if (office) {
            setOfficeGlobal(office)
        }
    }, [office])

    useEffect(() => {
        if (booking) {
            setUser(booking.user)
            setDate(moment(booking.date).format('DD.MM.YYYY'))
            setTimes({ timeBefore: `${timeToNumber(booking.before_hours)}`, timeAfter: `${timeToNumber(booking.after_hours)}` })
        }
    }, [booking])

    const closeThis = () => {
        setDate('')
        setTimes({ timeBefore: '', timeAfter: '' })
        upadateReservations()
        setBooking(null)
    }

    // useEffect(() => {
    //     date && office && getBookings(`&office=${office.id}&date_before=${date}&date_after=${date}&status=1`).then(res => {
    //         setBookings(res.data.results)
    //     })
    // }, [office, date, booking])

    // useEffect(() => {
    //     booking && !localTime.time && setTimes({timeBefore: `${timeToNumber(booking.before_hours)}`, timeAfter: `${timeToNumber(booking.after_hours)}`})
    // }, [booking])

    useEffect(() => {
        if (localTime.time && localTime.date) {
            setDate(localTime.date)
            setTimes({ timeBefore: '' + timeToNumber(localTime.time.split('-')[0]), timeAfter: '' + timeToNumber(localTime.time.split('-')[1]) })
        }
    }, [localTime])

    useEffect(() => {
        if (bookings && times.timeBefore && times.timeAfter && office) {
            const rangeTimes = bookings.map(item => {
                const bookingTimes = Array.from({ length: (timeToNumber(item.after_hours) - timeToNumber(item.before_hours)) * office.occupation_step }).map((_, index) => {
                    return timeToNumber(item.before_hours) + (index + 1) / office.occupation_step
                })
                return { array: bookingTimes, userId: item.user?.id }
            })
            let validationValue = false
            let validationMyBooking = false
            rangeTimes.forEach(item => {
                if (item.array.includes(+times.timeBefore + 1 / office.occupation_step) || item.array.includes(+times.timeAfter)) {
                    validationValue = true
                    if (user && item.userId === user.id) {
                        validationMyBooking = true
                    }
                }
            })
            setValidation(validationValue)
            setValidationMyField(validationMyBooking)
        }
    }, [times, bookings, office])

    const request = (finalyCallback: VoidFuncType) => {
        const currentCity = cities.find(item => item.value === +city)
        currentCity && getAllOffices(currentPage, 15, `&city=${currentCity.name}${!profile.is_superuser && !profile.cabinets_all ? `&user=${profile.id}` : ''}`)
            .then(res => {
                setOffices([...offices, ...res.data.results])
                setCurrentPage(prevState => prevState + 1)
            })
            .finally(() => finalyCallback())
    }

    useEffect(() => {
        if (cities.length) {
            const currentCity = cities.find(item => item.value === +city)
            setCurrentPage(2)
            currentCity && getAllOffices(1, 15, `&city=${currentCity.name}${!profile.is_superuser && !profile.cabinets_all ? `&user=${profile.id}` : ''}`)
                .then(res => {
                    setOffices(res.data.results)
                    setTotalCount(res.data.count)
                })
        }
    }, [city, cities])

    useEffect(() => {
        user && setSearch(`${user.last_name} ${user.first_name} ${user.middle_name}`)
    }, [user])

    useEffect(() => {
        resetCallback()
        getUsers(`page=1&page_size=10&search=${search}&is_psychologist=true${!profile.is_superuser && !profile.psychologist_all ? `&staff=${profile.id}` : ''}`)
            .then(res => {
                setUsers(res.data.results)
                setCountUser(res.data.count)
                successCallback()
            })
    }, [search])

    useEffect(() => {
        fetching && getUsers(`page=${currentUserPage}&page_size105&search=${search}&is_psychologist=true${!profile.is_superuser && !profile.psychologist_all ? `&staff=${profile.id}` : ''}`)
            .then(res => {
                setUsers([...users, ...res.data.results])
                successCallback()
            }).finally(() => finalyCallback())
    }, [fetching])

    const getOptions = () => {
        
        let optionsArr = [] as Array<OptionType>
        if (offices.length) {
            const availableOffices = offices.filter(office => office.status)
            optionsArr = [...optionsArr, ...availableOffices.map(item => ({ value: item.id, name: `${item.name} кабинет, ${item.address}` }))]
        }

        if (office && !offices.find(item => item.id === office.id)) {
            optionsArr = [...optionsArr, { value: office.id, name: `${office.name} кабинет, ${office.address}` }]
        }

        return optionsArr
    }

    const selectOffice = (id: number) => {
        const elem = offices.find(item => item.id === id)

        elem && setOffice(elem)
    }

    const initial = {
        requiresPayment: '1',
        period: '',
        date: localTime.date ? localTime.date : '',
        peoples: booking ? `${booking.peoples}` : '1'
    }

    useEffect(() => {
        date && booking && !localTime.time && setTimes({ timeBefore: `${timeToNumber(booking.before_hours)}`, timeAfter: `${timeToNumber(booking.after_hours)}` })
        !date && setTimes({ timeBefore: '', timeAfter: '' })
    }, [date])

    const FormikContext = () => {
        const { setFieldValue } = useFormikContext()
        useEffect(() => {
            setFieldValue('date', date)
            booking && setFieldValue('peoples', booking.peoples.toString())
            booking && setFieldValue('requiresPayment', booking.dnt_req_pay ? 2 : 1)
        }, [date, booking])
        return null
    }

    const getTimeOptions = (time?: number) => {
        if (office && date) {
            const weekDay = momentWeekDayToWeekDay(moment(date, 'DD.MM.YYYY').weekday())

            const times = office.schedule[weekDay].split('-').map(item => timeToNumber(item))

            return Array.from({ length: (times[1] - (time ? time : times[0]) + (1 / office.occupation_step)) * office.occupation_step }).map((_, index) => {
                const newTime = (time ? time : times[0]) + index / office.occupation_step
                return { value: newTime, name: Number.isInteger(newTime) ? `${newTime}:00` : `${Math.floor(newTime)}:30` }
            })
        }
        return office && !date ? [{ value: -1, name: 'Дата не выбрана' }] : !office && !date ? [{ value: -1, name: 'Оффис и дата не выбраны' }] : [{ value: -1, name: 'Оффис не выбран' }]
    }

    const excludeDates = useMemo(() => {
        if (office) {
            const disabledDays = Object.keys(office.schedule).filter(item => {
                if (office.schedule[+item] === '24:00-24:00') {
                    return item
                }
            })
            return disabledDays.reduce((acum: Array<Date>, item) => {
                return [...acum, ...searchAllWeekDaysOnMonth(currentMonth, weekDayToMomentWeekDay(+item))]
            }, [])
        }
    }, [office, currentMonth])

    const changeBooking = async (values: typeof initial) => {
        if (validation || validationMyField) return

        if (booking && office && date && times.timeAfter && times.timeBefore) {
            await deleteBooking(booking.id)

            const body = {
                before_hours: numberToTime(+times.timeBefore),
                after_hours: numberToTime(+times.timeAfter),
                user: !isGuestRole ? user?.id : null,
                date: moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                office: office.id,
                peoples: values.peoples,
                dnt_req_pay: +values.requiresPayment === 1 ? false : true,
                ...(guestValue && isGuestRole && { unknown_user: guestValue })
            }
            await createBooking(body)
                .then(() => {
                    showAlert('success', 'Бронирование успешно изменено')
                    update()
                    closeThis()
                }).catch((e) => {
                    show(e)
                })
        }
    }

    const addBooking = async (values: typeof initial) => {
        if (validation || validationMyField)
            return

        if (office && date && times.timeAfter && times.timeBefore) {
            const period = values.period ? +values.period : 1
            const datesArr = Array.from({ length: period }).map((i, index) => moment(date, 'DD.MM.YYYY').add(7 * index, 'd').format('YYYY-MM-DD'))

            for (const elem in datesArr) {
                const body = {
                    before_hours: numberToTime(+times.timeBefore),
                    after_hours: numberToTime(+times.timeAfter),
                    user: !isGuestRole ? user?.id : null,
                    date: datesArr[elem],
                    office: office.id,
                    peoples: values.peoples,
                    dnt_req_pay: +values.requiresPayment === 1 ? false : true,
                    ...(guestValue && isGuestRole && { unknown_user: guestValue })
                }
                await createBooking(body)
                    .then(() => {
                        showAlert('success', 'Бронирование успешно добавлено')
                    })
                    .catch((e) => {
                        show(e)
                    })
            }
            update()
            closeThis()
        }
    }

    return (
        <PopupWrapper>
            <ErrorHandler active={active} hidden={hidden} text={text} />
            <Flex padding="5px 0" border="1px solid #DECEB7">
                <ArrowBackWrapper onClick={close}>
                    <ArrowBackIcon />
                </ArrowBackWrapper>
                <Typography weight="700" size="18px" lineHeight="21px">Создание брони</Typography>
            </Flex>
            <Flex padding="5px 20px">
                <Formik
                    initialValues={initial}
                    onSubmit={(values) => {
                        if ((!isGuestRole && !user) || (isGuestRole && !guestValue.length)) {
                            showAlert('error', `Укажите ${isGuestRole ? 'ФИО гостя' : 'ФИО психолога'}`)
                            return
                        }
                        booking ? changeBooking(values) : addBooking(values)
                    }}
                >
                    <Form>
                        <Flex wraping align="flex-start" column="10px" gap="10px" width="100%" margin="5px 0 0 0" justify="flex-start">
                            <Flex width="300px" align="flex-satrt" direction="column">
                                <Label>Город</Label>
                                <Select
                                    onChange={(value) => setCity(`${value}` as string)}
                                    options={cities}
                                    value={city}
                                    defaultValue="Выберите"
                                />
                            </Flex>
                            <Flex width="300px" align="flex-satrt" direction="column">
                                <Label>Кабинет</Label>
                                <Select
                                    options={getOptions()}
                                    onChange={(value) => selectOffice(+value)}
                                    value={office && offices.length ? `${office.id}` : ''}
                                    totalCount={totalCount}
                                    request={request}
                                    currentLength={offices.length}
                                    defaultValue="Выберите"
                                />
                            </Flex>
                            <DropdownSearch width="300px" ref={ref} direction="column" align="flex-satrt">
                                <Flex justify='start'>
                                    <SwitchingLabel
                                        isActive={!isGuestRole}
                                        onClick={() => setIsGuestRole(false)}
                                    >
                                        Психолог
                                    </SwitchingLabel>
                                    <SwitchingLabel
                                        isActive={isGuestRole}
                                        onClick={() => setIsGuestRole(true)}
                                        marginLeft
                                    >
                                        Гость
                                    </SwitchingLabel>
                                </Flex>
                                {
                                    isGuestRole
                                        ?
                                        <Input
                                            value={guestValue}
                                            onChange={(e: ChangeType) => setGuestValue(e.target.value)}
                                            placeholder="Гость"
                                        />
                                        :
                                        <Input
                                            value={search}
                                            onChange={(e: ChangeType) => setSearch(e.target.value)}
                                            placeholder="ФИО / Email / Телефон"
                                            onFocus={() => setShowList(true)}
                                        />
                                }
                                {showList &&
                                    <List onScroll={scroll}>
                                        {!!users.length ?
                                            users.map(item =>
                                                <ListItem
                                                    onClick={() => {
                                                        setUser(item)
                                                        setShowList(false)
                                                    }}
                                                    key={item.id}
                                                >
                                                    <Typography size="16px" lineHeight="20px">{item.last_name} {item.first_name} {item.middle_name}</Typography>
                                                </ListItem>
                                            )
                                            :
                                            <ListItem><Typography size="16px" lineHeight="20px">Ничего не найдено</Typography></ListItem>
                                        }
                                    </List>
                                }
                            </DropdownSearch>
                            <Flex width="300px">
                                <Field name="peoples" label="Кол-во человек" direction="column" type="number" max={`${office?.max_peoples || 1}`} placeholder="Кол-во человек" component={FormikInput} />
                            </Flex>
                            <Flex width="300px">
                                <Field
                                    setCurrentMonth={setCurrentMonth}
                                    excludeDates={excludeDates}
                                    name="date"
                                    direction="column"
                                    label="Дата бронирования"
                                    component={DateFormikFilter}
                                    disabled
                                />
                            </Flex>

                            <Flex width="150px" align="flex-satrt" direction="column">
                                <Label>Время с</Label>
                                <Input disabled value={numberToTime(+times.timeBefore).slice(0, 5)} onChange={() => { }} />
                            </Flex>
                            <Flex width="150px" align="flex-satrt" direction="column">
                                <Label>Время до</Label>
                                <Input disabled value={numberToTime(+times.timeAfter).slice(0, 5)} onChange={() => { }} />
                            </Flex>
                            <Flex gap="10px" width="600px">
                                <Field
                                    name="requiresPayment"
                                    options={[
                                        { value: 1, name: 'Требует оплаты' },
                                        { value: 2, name: 'Не требует оплаты' }
                                    ]}
                                    component={FormikSelect}
                                    direction="column"
                                    label="Оплата"
                                />
                                {!booking &&
                                    <Field
                                        name="period"
                                        options={[
                                            { value: 4, name: 'Каждую неделю на ближайший месяц' },
                                            { value: 12, name: 'Каждую неделю на ближайшие три месяца' },
                                            { value: 24, name: 'Каждую неделю на ближайшие полгода' },
                                        ]}
                                        component={FormikSelect}
                                        direction="column"
                                        label="Период брони"
                                        defaultValue="Без периода"
                                    />
                                }
                            </Flex>
                            {/* {validation && !validationMyField &&
                                    <Typography margin="4px 0 0 0" fontFamily="Montserrat" size="12px" lineHeight="15px" color={COLORS.red}>
                                        Это время уже занято
                                    </Typography>
                                }
                                {validationMyField &&
                                    <Typography margin="4px 0 0 0" fontFamily="Montserrat" size="12px" lineHeight="15px" color={COLORS.red}>
                                        Вы уже забронировали это время
                                    </Typography>
                                } */}
                        </Flex>
                        <Flex padding="5px 0" justify="flex-end" width="100%">
                            <Flex>
                                {booking &&
                                    <>
                                        <Button
                                            type="button"
                                            theme="red"
                                            radius="21px"
                                            width="200px"
                                            height="30px"
                                            weight="500"
                                            lineHeight="17px"
                                            margin="0 10px 0 0"
                                            onClick={() => setBooking(null)}
                                        >
                                            Отменить редактирование
                                        </Button>
                                        <Button
                                            type="button"
                                            theme="grey"
                                            radius="21px"
                                            width="200px"
                                            height="30px"
                                            weight="500"
                                            margin="0 10px 0 0"
                                            lineHeight="17px"
                                            onClick={() => dispatch(bookingCancel({ group: booking.id, successCallback: closeThis }))}
                                        >
                                            Отменить бронирование
                                        </Button>
                                    </>
                                }
                                <Button
                                    type="submit"
                                    theme={booking ? 'green' : 'grey'}
                                    radius="21px"
                                    width="150px"
                                    height="30px"
                                    weight="600"
                                    lineHeight="17px"
                                >
                                    {booking ? 'Сохранить' : 'Забронировать'}
                                </Button>

                            </Flex>
                        </Flex>
                        <FormikContext />
                    </Form>
                </Formik>
            </Flex>
        </PopupWrapper>
    );
};

export default BookingsPopup;

