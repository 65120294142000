import { AxiosResponse } from "axios";
import { IBalance } from "../../types/interfaces/balance";
import { BookingSendType, IBooking } from "../../types/interfaces/booking";
import { IComplaints } from "../../types/interfaces/complaints";
import { IHistory } from "../../types/interfaces/history";
import { INotification } from "../../types/interfaces/notification";
import { ICreateOffice, IOffice } from "../../types/interfaces/office";
import { IOrder } from "../../types/interfaces/order";
import {
  IChangePriceCategory,
  ICreatePriceCategory,
  IPriceCategory,
} from "../../types/interfaces/priceCategories";
import { IProduct } from "../../types/interfaces/product";
import {
  IBookingStatistics,
  IOfficeStatistics,
  IPsyhoStatistics,
} from "../../types/interfaces/statistics";
import { ISubscription } from "../../types/interfaces/subscription";
import {
  CreateStaffType,
  CreateUserType,
  UpdateType,
} from "../../types/interfaces/user";
import { IUser } from "../../types/interfaces/user";
import { IWithdrawal } from "../../types/interfaces/withdrawal";
import { instance } from "./index";

type AxiosPromise<T = any> = Promise<AxiosResponse<T>>;

// User

export const loginUser = (data: {
  email: string;
  password: string;
}): AxiosPromise<{ refresh: string; access: string }> => {
  return instance.post("/auth/jwt/create/", data);
};

export const getUserProfile = (): AxiosPromise<IUser> => {
  return instance.get("/auth/users/me/");
};

export const patchMineProfile = (
  data: Partial<Pick<IUser, keyof IUser>>
): AxiosPromise<IUser> => {
  return instance.patch("/auth/users/me/", data);
};

export const refresh = (data: {
  refresh: string;
}): AxiosPromise<{ access: string }> => {
  return instance.post("/auth/jwt/refresh/", data);
};

export const resetPassword = (email: string): AxiosPromise<{}> => {
  return instance.post("/auth/users/reset_password/", { email });
};

export const recoveryPassword = (data: {
  [key: string]: string;
}): AxiosPromise<{}> => {
  return instance.post("/auth/users/reset_password_confirm/", data);
};

export const createPassword = (data: {
  [key: string]: string;
}): AxiosPromise<{}> => {
  return instance.post("/auth/users/set_password/", data);
};

export const updateUser = (
  data: CreateUserType | CreateStaffType | UpdateType,
  id = "me"
): AxiosPromise<IUser> => {
  return instance.patch(`/auth/users/${id}/`, data);
};

export const deleteUser = (id: number) => {
  return instance.post(`/auth/users/${id}/delete_user/`);
};

export const getUsers = (
  queryString: string
): AxiosPromise<{
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<IUser>;
}> => {
  return instance.get(`/auth/users/?${queryString}`);
};

export const banUser = (id: number): AxiosPromise<{}> => {
  return instance.post(`/auth/users/${id}/ban_user/`);
};

export const unbanUser = (id: number): AxiosPromise<{}> => {
  return instance.post(`/auth/users/${id}/unban_user/`);
};

export const changePassword = (
  id: number,
  body: { password: string }
): AxiosPromise<{}> => {
  return instance.post(`/auth/users/${id}/set_user_password/`, body);
};

export const createUser = (
  body: CreateUserType | CreateStaffType
): AxiosPromise<IUser> => {
  return instance.post("/auth/users/", body);
};

export const getUsersOfNotification = (body: {
  recipient_list: Array<number>;
}): AxiosPromise<{ users: Array<{ get_full_name: string }> }> => {
  return instance.post("/notifications/get_recipient_list/", body);
};

// City / Metro

export const getSelectParams = (
  paramsPath: string,
  queryString = ""
): AxiosPromise<Array<{ id: number; name: string; city?: number }>> => {
  return instance.get(`/office/${paramsPath}/${queryString}`);
};

export const deleteCity = (id: number): AxiosPromise<{}> => {
  return instance.delete(`/office/cities/${id}/`);
};

export const deleteMetro = (id: number): AxiosPromise<{}> => {
  return instance.delete(`/office/metros/${id}/`);
};

export const addMetro = (body: {
  name: string;
  city: number;
}): AxiosPromise<{}> => {
  return instance.post("/office/metros/", body);
};

export const addCity = (body: { name: string }): AxiosPromise<{}> => {
  return instance.post("/office/cities/", body);
};

export const getCbinetsOfCity = (
  id: number
): AxiosPromise<Array<{ pk: number }>> => {
  return instance.get(`/office/cities/${id}/cabinets/`);
};

export const getCbinetsOfMetro = (
  id: number
): AxiosPromise<Array<{ pk: number }>> => {
  return instance.get(`/office/metros/${id}/cabinets/`);
};

// Office

export const deleteOffice = (id: number): AxiosPromise<{}> => {
  return instance.delete(`/office/offices/${id}/`);
};

export const deletePhotoOnCabinet = (
  id: number,
  path: string
): AxiosPromise<{}> => {
  return instance.delete(`/office/${path}/${id}/`);
};

export const getAllOffices = (
  page = 1,
  pageSize = 15,
  queryString = ""
): AxiosPromise<{ results: Array<IOffice>; count: number }> => {
  return instance.get(
    `/office/offices/?page=${page}&page_size=${pageSize}${queryString}`
  );
};

export const getOffice = (id: string): AxiosPromise<IOffice> => {
  return instance.get(`/office/offices/${id}/`);
};

export const getRoadImg = (
  id: string
): AxiosPromise<Array<{ id: number; photo: string; user: number }>> => {
  return instance.get(`/office/photosgetoffice/?office=${id}`);
};

export const officeCreate = (body: ICreateOffice): AxiosPromise<IOffice> => {
  return instance.post("/office/offices/", body);
};

export const changeOffice = (
  body: ICreateOffice & { id: number },
  id: number
): AxiosPromise<IOffice> => {
  return instance.patch(`/office/offices/${id}/`, body);
};

export const addPhotoForCabinet = (
  body: any,
  path: string
): AxiosPromise<{}> => {
  return instance.post(`/office/${path}/`, body);
};

// Booking

export const createBooking = (
  data: BookingSendType
): AxiosPromise<IBooking> => {
  return instance.post("/office/groupbookings/", data);
};

export const getBookings = (
  queryString: string,
  pageSize = 999
): AxiosPromise<{ results: Array<IBooking>; count: number }> => {
  return instance.get(
    `/office/groupbookings/?page_size=${pageSize}${queryString}`
  );
};

export const cancelBooking = (body: { group: number }): AxiosPromise<{}> => {
  return instance.post(`/office/groupbookings/${body.group}/cancel_by_group/`);
};

export const deleteBooking = (group: number): AxiosPromise<{}> => {
  return instance.post(`/office/groupbookings/${group}/delete_by_group/`);
};

export const сonfirmBooking = ({
  group,
}: {
  group: number;
}): AxiosPromise<IBooking> => {
  return instance.post(`/office/groupbookings/${group}/agree_by_group/`);
};

export const createReport = (body: IComplaints): AxiosPromise<{}> => {
  return instance.post("/office/complaints/", body);
};

export const getReports = (queryString: string) => {
  return instance.get(`/office/complaints/${queryString}`);
};

export const changeBooking = (
  id: number,
  body: { after_hours: string; before_hours: string; date: string }
): AxiosPromise<IBooking> => {
  return instance.post(`/office/groupbookings/${id}/change_time/`, body);
};

// PaymentSystems

export const getPaymentSystems = (): AxiosPromise<
  Array<{ id: number; name: string }>
> => {
  return instance.get("/office/paymentsystems/");
};

export const getOrders = (
  queryString: string
): AxiosPromise<{ count: number; results: Array<IOrder> }> => {
  return instance.get(`/payments/orders/${queryString}`);
};

export const getHistoryBalance = (
  queryString: string
): AxiosPromise<{ count: number; results: Array<IWithdrawal> }> => {
  return instance.get(`/payments/withdrawal/${queryString}`);
};

export const buySubscription = (body: {
  subscription: number;
}): AxiosPromise<{ status: number; PaymentURL: string }> => {
  return instance.post("/payments/balances/buy_subscription/", body);
};

export const buyHours = (body: {
  price_category: number;
  count: number;
  booking?: number;
}): AxiosPromise<{ status: number; PaymentURL: string }> => {
  return instance.post("/payments/balances/buy_hours/", body);
};

export const getProducts = (
  isGroup: boolean,
  query: string
): AxiosPromise<Array<IProduct>> => {
  return instance.get(`/payments/products/?is_group=${isGroup}${query}`);
};

// PriceCategories

export const getPriceCategories = (
  queryString = ""
): AxiosPromise<Array<IPriceCategory>> => {
  return instance.get(`/office/pricecategories/${queryString}`);
};

export const addPriceCategories = (
  body: ICreatePriceCategory
): AxiosPromise<{}> => {
  return instance.post(`/office/pricecategories/`, body);
};

export const changePriceCategories = (
  id: number,
  body: IChangePriceCategory
): AxiosPromise<{}> => {
  return instance.patch(`/office/pricecategories/${id}/`, body);
};

export const deletePriceCategory = (id: number): AxiosPromise<{}> => {
  return instance.delete(`/office/pricecategories/${id}/`);
};

// History

export const getHistoryForUser = (
  id: number,
  queryString: string
): AxiosPromise<{ count: number; results: Array<IHistory> }> => {
  return instance.get(
    `/history/balance_changes/?psychologist=${id}${queryString}`
  );
};

export const getAllHistory = (
  queryString: string
): AxiosPromise<{ count: number; results: Array<IHistory> }> => {
  return instance.get(`/history/balance_changes/${queryString}`);
};

// Balance

export const changeBalance = (body: {
  user: number;
  balances: Array<{ count: number; price_category: number }>;
}): AxiosPromise<{}> => {
  return instance.post(`/payments/balances/change_balance/`, body);
};

export const getBalanceForUser = (
  id: number,
  queryString = ""
): AxiosPromise<Array<IBalance>> => {
  return instance.get(`/payments/balances/?user=${id}${queryString}`);
};

// Statistics

export const getOfficeStatistics = (
  queryString: string
): AxiosPromise<{ results: Array<IOfficeStatistics>; count: number }> => {
  return instance.get(`/statistics/offices/${queryString}`);
};

export const getPsyhoStatistics = (
  queryString: string
): AxiosPromise<{ results: Array<IPsyhoStatistics>; count: number }> => {
  return instance.get(`/statistics/psychologists/${queryString}`);
};

export const getBookingStatistics = (
  queryString: string
): AxiosPromise<{ results: Array<IBookingStatistics>; count: number }> => {
  return instance.get(`/statistics/bookings/${queryString}`);
};

export const getStatisticsSummary = (
  queryString: string,
  path: string
): AxiosPromise<{ hours: number; count: number }> => {
  return instance.get(`/statistics/${path}/${queryString}`);
};

// Subscriptions

export const getSubscriptions = (
  queryString = ""
): AxiosPromise<Array<ISubscription>> => {
  return instance.get(`/payments/subscriptions/${queryString}`);
};

export const addSubscriptions = (
  body: Omit<ISubscription, "id" | "price_category"> & {
    price_category: number;
  }
): AxiosPromise<{}> => {
  return instance.post("/payments/subscriptions/", body);
};

export const changeSubscriptions = (
  body: Omit<ISubscription, "id" | "price_category"> & {
    price_category: number;
  },
  id: number
): AxiosPromise<{}> => {
  return instance.patch(`/payments/subscriptions/${id}/`, body);
};

export const getSubscriptionsOfUser = (
  id: number,
  libary: string
): AxiosPromise<Array<ISubscription>> => {
  return instance.get(`/office/subscriptions/?user=${id}&library=${libary}/`);
};

export const deleteSubscriptions = (id: number): AxiosPromise<{}> => {
  return instance.delete(`/payments/subscriptions/${id}/`);
};

// Notifications

export const createNotifications = (body: {
  text: string;
  recipient_list: Array<number>;
}): AxiosPromise<INotification> => {
  return instance.post(`/notifications/`, body);
};

export const addPhotoToNotification = (body: FormData): AxiosPromise<{}> => {
  return instance.post("/images/", body);
};

export const getNotifications = (
  queryString: string
): AxiosPromise<{ count: number; results: Array<INotification> }> => {
  return instance.get(`/notifications/${queryString}`);
};

export const getNotificatinsUnreaded = (): AxiosPromise<{ count: number }> => {
  return instance.get("/notifications/unreaded/");
};

export const readNotification = (body: {
  recipient_list: Array<number>;
}): AxiosPromise<{}> => {
  return instance.post("/notifications/read/", body);
};

export const deleteComplains = (id: number): AxiosPromise<{}> => {
  return instance.delete(`/office/complaints/${id}/`);
};
