import React, { useEffect, useState } from "react";
import Menu from "../Menu";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";
import { Container } from "./style";
import Profile from "../../pages/Profile";
import { Background } from "../common/Styled/Background";
import Cabinets from "../../pages/Cabinets";
import CabinetPage from "../../pages/Cabinets/CabinetsCatalog/CabinetPage";
import Bookings from "../../pages/Bookings";
import Users from "../../pages/adminPages/Users";
import AdminCabinets from "../../pages/adminPages/Cabinets";
import AddCabinetPage from "../../pages/adminPages/Cabinets/AddCabinetPage";
import AdminBookings from "../../pages/adminPages/Bookings";
import Statistics from "../../pages/adminPages/Statistics";
import Settings from "../../pages/adminPages/Settings";
import History from "../../pages/adminPages/History";
import AdminNotifications from "../../pages/adminPages/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers/rootReducer";
import Notifications from "../../pages/Notifications";
import { getUnReadedNotificationCount } from "../../store/reducers/notificationReducer";
import Subscriptions from "../../pages/Subscriptions";
import Rules from "../../pages/rules";
import Agreement from "../../pages/Login/Steps/Agreement";

const MainRouter = () => {
  const {
    user: { profile },
    notification: { unReadedCount },
  } = useSelector((state: RootState) => state);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (profile.is_psychologist) {
      dispatch(getUnReadedNotificationCount());
    }
  }, [pathname]);

  if (profile.is_psychologist && !profile.is_accept)
    return (
      <Container align="normal">
        <Route path="*" exact render={() => <Redirect to="/agreement" />} />
        <Route path="/agreement" exact render={() => <Agreement />} />
      </Container>
    );

  return (
    <Container align="normal">
      <Menu unReadedCount={unReadedCount} isPsyho={profile.is_psychologist} />
      <Background>
        <Switch>
          {profile.is_psychologist ? (
            <>
              <Route path="/booking/:id" component={Bookings} />
              <Route path="/booking" component={Bookings} />
              <Switch>
                <Route path="/offices/catalog/:id" component={CabinetPage} />
                <Route path="/offices" component={Cabinets} />
              </Switch>
              <Route path="/notifications" component={Notifications} />
              <Route path="/purchases" component={Subscriptions} />
              <Route path="/rules" component={Rules} />
              <Route path="/profile" component={Profile} />
              <Route path="/" exact render={() => <Redirect to="/offices" />} />
              <Route path="*" exact render={() => <Redirect to="/offices" />} />
            </>
          ) : (
            <>
              <Route path="/users" component={Users} />
              <Route path="/booking" component={AdminBookings} />
              <Route path="/statistics" component={Statistics} />
              <Route path="/settings" component={Settings} />
              <Route path="/history" component={History} />
              <Route path="/profile" component={Profile} />
              <Route path="/notifications" component={AdminNotifications} />
              <Switch>
                <Route path="/offices/add" component={AddCabinetPage} />
                <Route path="/offices/change/:id" component={AddCabinetPage} />
                <Route path="/offices" component={AdminCabinets} />
              </Switch>
              <Route path="/" exact render={() => <Redirect to="/offices" />} />
            </>
          )}
        </Switch>
      </Background>
    </Container>
  );
};

export default MainRouter;
