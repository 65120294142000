import { Typography } from "../../../../../components/common/Styled/Typography";

export const Article11 = () => (
  <Typography fontFamily="Montserrat" size="16px" align="justify">
    <Typography fontFamily="Montserrat" weight={600} size="16px" align="center">
      Статья 11. Иные условия
    </Typography>
    11.1. Арендатор не имеет права использовать содержимое сайта, товарный знак
    и другие объекты интеллектуальных прав, права на которые принадлежат
    Арендодателю, без письменного согласия соответствующего правообладателя.
    <br />
    11.2. Контакты Арендодателя: телефон +7 (911) 925-25-35, электронная почта
    psy-office.pro@yandex.ru <br />
    Банковские реквизиты Арендодателя <br />
    Расчетный счет: 40802810400002597664, <br />
    Банк: АО «ТБанк», <br />
    ИНН банка: 7710140679, <br />
    БИК банка: 044525974 <br />
    Корреспондентский счет банка: 30101810145250000974 <br />
    Юридический адрес банка: Москва, 127287, ул. Хуторская 2-я, д. 38А, стр. 26
    <br />
    11.3. Юридически значимые сообщения, в том числе и досудебные претензии,
    связанные с возникновением, изменением или прекращением обязательств,
    основанных на этом договоре, направляются исключительно по указанному в нем
    адресу электронной почты: psy-office.pro@yandex.ru
    <br />
    <br />
  </Typography>
);
